import React from 'react';
import Countdown from 'react-countdown';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Button } from '@components/common/elements';
import { AnalyticsEventType, ButtonType, IProvidersFilterState } from '@types';
import { useSelector } from 'react-redux';
import { pathsConfig } from '@config/paths';
import { useNavigate } from 'react-router-dom';
import { getImageByURL, sendAnalyticsHandler } from '@utils';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import s from './style.module.scss';
import { BGamingIcon } from '@components/svg';

type DataType = {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
};

export const TimerBlock = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'BgamingPrizes'
	});

	const { langUrlPrefix } = useUrlLang();

	const navigate = useNavigate();

	const { isTablet } = useSelector(commonUISelectors.commonUIInfo);

	const navigateHandler = (state: IProvidersFilterState) => {
		setTimeout(
			() => navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link, { state }),
			200
		);
	};

	const stateForFilter: IProvidersFilterState = {
		provider: { name: 'BGaming', id: 93 }
	};

	const btnNavHandler = () => {
		navigateHandler(stateForFilter);

		sendAnalyticsHandler(AnalyticsEventType?.bgaming_click_play);
	};

	const renderer = ({ days, hours, minutes, seconds }: DataType) => {
		return (
			<div className={s.counter}>
				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{days > 9 ? days?.toString()[0] : 0}</div>
						<div>{days > 9 ? days?.toString()[1] : days}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('day')}</span>
				</div>

				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{hours > 9 ? hours?.toString()[0] : 0}</div>
						<div>{hours > 9 ? hours?.toString()[1] : hours}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('hours')}</span>
				</div>

				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{minutes > 9 ? minutes?.toString()[0] : 0}</div>
						<div>{minutes > 9 ? minutes?.toString()[1] : minutes}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('minutes')}</span>
				</div>

				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{seconds > 9 ? seconds?.toString()[0] : 0}</div>
						<div>{seconds > 9 ? seconds?.toString()[1] : seconds}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('seconds')}</span>
				</div>
			</div>
		);
	};

	const img = getImageByURL('../../../../../../assets/img/common/bgaming-prize.webp');

	return (
		<div className={s.container}>
			{!isTablet && (
				<div className={s.text_block}>
					<div className={s.imgBlock}>
						<img
							className={s.img}
							src={img}
							alt='Bgaming prize'
						/>
					</div>
				</div>
			)}

			<div className={s.counterWrapper}>
				<div className={s.titleBlock}>
					<BGamingIcon />
					<h2>{localizeText('title_bgaming_prize')}</h2>
				</div>

				{isTablet && (
					<img
						className={s.img}
						src={img}
						alt='Bgaming prize'
					/>
				)}

				<div className={s.counterBlock}>
					<div className={s.counterBlock_bottom}>
						<div className={s.timer}>
							<div className={s.timer_titleBlock}>
								<div className={s.timer_title}>{localizeText('timer_block_title')}</div>

								<Countdown
									date={new Date('2024-09-30T23:59:00+03:00')}
									renderer={renderer}
									daysInHours
								/>
							</div>

							<span className={s.endDate}>{localizeText('timer_end_date')}</span>

							<Button
								rippleAnimation
								className={s.contactBtn}
								type={ButtonType?.primary}
								handle={btnNavHandler}
							>
								{localizeText('play')}
							</Button>
						</div>
					</div>
				</div>

				<div className={s.description}>{localizeText('description_bgaming_prize')}</div>
			</div>
		</div>
	);
};
