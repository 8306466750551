import React, { useEffect, useState } from 'react';
import {
	FilterRequestType,
	IGameCategory,
	IProviderGame,
	ProviderGamesType,
	IProvidersFilter,
	CategoriesKeys
} from '@types';
import {
	CardPreloader,
	Preloader,
	ProviderGameCard,
	Ripple
} from '@components/common/elements';
import { getGames } from '@lib/api/game-provider';
import { GamesRequestOptions } from '@lib/api/game-provider/getGames';
import { FilterType, filterStringGenerator } from '@utils';
import { useLocation } from 'react-router-dom';
import { useLocales } from '@lib/hooks';
import handleViewport, { InjectedViewportProps } from 'react-in-viewport';
import { getGamesByCategories } from '@lib/browser-storage';
import { isTopXGo } from '@config/global';
import { CategoriesList } from './parts';
import s from './style.module.scss';

interface ITab {
	title: string;
	category: IGameCategory;
}

type GamesViewerP = {
	allCategories: Array<ITab>;
	category: IGameCategory;
	favoriteHandler: () => void;
	isFavorite: boolean;
	onCategoryClick: (
		tabid: number,
		categoryKey: string,
		isDeleteCategory?: boolean
	) => void;
};

const gamesLimit: number = process.env.GAMES_LIMIT ? +process.env.GAMES_LIMIT : 16;

const Component = (props: InjectedViewportProps<HTMLDivElement> & GamesViewerP) => {
	const { category, allCategories, onCategoryClick } = props;

	const { inViewport, forwardedRef } = props;

	const location = useLocation();
	let provider: IProvidersFilter | null = null;

	if (location.state) {
		provider = location.state.provider;
	} else {
		provider = null;
	}

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [loadedGames, setLoadedGames] = useState<IProviderGame[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFullyLoaded, setIsFullyLoaded] = useState<boolean>(false);
	const [isFilterApplied, setIsFilterApplied] = useState<boolean>(
		provider ? true : false
	);

	const { localizeText } = useLocales({
		path: 'components/common/elements',
		node: 'GamesViewer'
	});

	useEffect(() => {
		if (provider) {
			setIsFilterApplied(true);
		}
	}, [provider]);

	const loadGames = async (
		isFirstPage: boolean = true,
		page: number,
		filterAplied?: boolean
	): Promise<void> => {
		const filterData: Record<string, unknown> = {};

		if (category.key !== CategoriesKeys?.all) {
			filterData.categoryIds = category.id;
		}

		if (filterAplied && provider && category.key === CategoriesKeys?.all) {
			filterData.providerIds = provider.id;
		}

		if (filterData?.categoryIds && page <= currentPage) {
			const getSavedGames = getGamesByCategories(
				filterStringGenerator(FilterType.IN, filterData)?.toString()
			);

			if (getSavedGames) {
				const getSavedGamesJson: {
					page: number;
					games: IProviderGame[];
					isFullyLoaded: boolean;
				} = JSON.parse(getSavedGames);

				if (getSavedGamesJson) {
					setCurrentPage(getSavedGamesJson?.page);
					setLoadedGames(getSavedGamesJson?.games);
					setIsFullyLoaded(getSavedGamesJson?.isFullyLoaded);
					setIsLoading(false);

					return;
				}
			}
		}

		const filter: FilterRequestType = filterStringGenerator(FilterType.IN, filterData);

		const options: GamesRequestOptions = {
			limit: gamesLimit,
			page: page,
			filter
		};

		await getGames(
			options,
			isFirstPage,
			ProviderGamesType.providerGames,
			undefined,
			setIsFullyLoaded
		)
			.then((data) => {
				const loaded = isFirstPage
					? [...data.filter((game) => loadedGames.includes(game) === false)]
					: [
						...loadedGames,
						...data.filter((game) => loadedGames.includes(game) === false)
					];

				setLoadedGames(loaded);
			})
			.then(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		loadGames(true, currentPage, isFilterApplied);
	}, [allCategories, isFilterApplied]);

	const loadMoreGames = () => {
		if (!isLoading && !isFullyLoaded) {
			setIsLoading(true);
			setTimeout(() => {
				loadGames(false, currentPage + 1, isFilterApplied);
				setCurrentPage((p) => p + 1);
			}, 200);
		}
	};

	useEffect(() => {
		if (inViewport && !isTopXGo) {
			loadMoreGames();
		}
	}, [inViewport]);

	const resetProvider = () => {
		window.history.replaceState({}, document.title);

		if (location?.state && location?.state?.provider) {
			location.state.provider = null;
		}

		setLoadedGames([]);
		setCurrentPage(1);

		setIsFilterApplied(false);
		loadGames(true, 1, false);
	};

	useEffect(() => {
		if (!provider && isFilterApplied) {
			resetProvider();
		}
	}, [location]);

	return (
		<div className={s.gamesViewer}>
			<CategoriesList
				allCategories={allCategories}
				isFilterApplied={isFilterApplied}
				category={category}
				resetProvider={resetProvider}
				onCategoryClick={onCategoryClick}
			/>

			<div className={s.gamesViewerGames}>
				{loadedGames?.length === 0 ? (
					<CardPreloader
						length={
							category?.gamesCount > 6 || !category?.gamesCount ? 6 : category?.gamesCount
						}
					/>
				) : (
					loadedGames.map((game) => {
						return (
							<ProviderGameCard
								key={game.key}
								className={s.gamesViewerCard}
								providerName={game.providerName}
								route={game.key}
								name={game.name}
								img={game.image}
								onlyImage={true}
								isFavorite={game?.isFavourite}
								id={game?.id}
								providerGamesType={ProviderGamesType.providerGames}
								setLoadedGames={setLoadedGames}
								isDemo={game?.isDemo}
								isFreezeBalancePlay={game?.isFreezeBalancePlay}
								filterProviderName={provider?.name}
								filterCategoryName={category.key}
							/>

						);
					})
				)}
			</div>

			{!isFullyLoaded && loadedGames?.length > 0 && (
				<div className={s.gamesViewerFooter}>
					{isLoading ? (
						<Preloader className={s.gamesViewerPreloader} />
					) : (
						<button
							className={s.gamesViewerLoadMoreButton}
							onClick={loadMoreGames}
						>
							{localizeText('load_more')}
							<Ripple />
						</button>
					)}
				</div>
			)}

			{!isFullyLoaded && loadedGames?.length > 0 && <div ref={forwardedRef} />}
		</div>
	);
};

export const GamesViewer = handleViewport(Component);
