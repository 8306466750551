import React, { MouseEvent, PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { TModalContentProps } from './Modal.types';
import Div100vh from 'react-div-100vh';
import cn from 'classnames';

import s from './style.module.scss';

const Root = (props: PropsWithChildren) => {
    const { children } = props;

    const modalRoot = document.getElementById('modal-portal');

    if (!modalRoot) {
        return null;
    }

    return ReactDOM.createPortal(
        children,
        modalRoot
    );
};

const Content = (props: TModalContentProps) => {
    const { children, isMobile, onClose, className = '', isAndroidBanner = false, isVisibleHeader = false } = props;

    const handleClose = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        onClose();
    };
    const handleWrapperClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    /* eslint-disable consistent-return */
    useEffect(() => {
        const body = document.querySelector('body');

        if (body) {
            body.style.overflow = 'hidden';

            return () => {
                body.style.overflow = 'auto';

            };
        }
    }, []);

    return (
        <div
            className={cn(
                s.modalWrapper,
                { [s.androidBanner]: isAndroidBanner },
                { [s.isVisibleHeader]: isVisibleHeader },
                className
            )}
            onClick={handleClose}
        >
            {!isMobile && <div onClick={handleWrapperClick} className={s.inner}>{children}</div>}
            {isMobile && <Div100vh onClick={handleWrapperClick} className={s.inner}>{children}</Div100vh>}
        </div>
    );
};

export const Modal = {
    Root,
    Content
};

