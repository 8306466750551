import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';
import { AnalyticsEventType } from '@types';
import { userSelectors } from '@store/reducers/user/selectors';
import { sendAnalyticsHandler } from '@utils';
import {
	AuthedDepositeBonusToast,
	NotAuthedDepositeBonusToast
} from '@components/common/global-settings';
import { commonUiConfig } from '@config/common-ui';
import { useNavigate } from 'react-router-dom';
import { useUrlLang } from '@lib/hooks';
import s from './style.module.scss';
import cn from 'classnames';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';

export const DepositeBanners = () => {
	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { createdAt, successInvoiceCount } = useSelector(userSelectors.userInfo);
	const { modalType, isSidebarOpened } = useSelector(commonUISelectors.commonUIInfo);

	const toastNotAuthedId = useRef<string | null>();
	const toastAuthedId = useRef<string | null>();
	const timeoutId = useRef<ReturnType<typeof setTimeout> | null>();

	const closeModalAuthed = () => {
		localStorage?.setItem('deposite_bonus_authed', 'true');

		sendAnalyticsHandler(AnalyticsEventType?.popup_close, {
			name_popup: isAuthed
				? 'Deposite bonus authed user'
				: 'Deposite bonus non-authed user'
		});
	};

	const clearToasts = () => {
		timeoutId?.current && clearTimeout(timeoutId.current);
		toastNotAuthedId?.current && toast.dismiss(toastNotAuthedId.current);
		toastAuthedId?.current && toast.dismiss(toastAuthedId.current);
	};

	useEffect(() => {
		clearToasts();
		const closedBonusModal = localStorage?.getItem('deposite_bonus_authed');

		if (!modalType && !isSidebarOpened) {
			if (!isAuthed) {
				timeoutId.current = setTimeout(() => {
					toast(
						(t) => {
							toastNotAuthedId.current = t.id;

							return (
								<NotAuthedDepositeBonusToast
									onClose={closeModalAuthed}
									t={t}
								/>
							);
						},
						{ duration: commonUiConfig.infinityDuration, className: s.toastNonAuthed }
					);

					sendAnalyticsHandler(AnalyticsEventType?.popup_show, {
						name_popup: 'Deposite bonus non-authed user'
					});
				}, 15000);
			} else {
				if (
					isAuthed &&
					dayjs(createdAt).add(1, 'h').format() > dayjs().format() &&
					!closedBonusModal &&
					successInvoiceCount < 1
				) {
					toast(
						(t) => {
							toastAuthedId.current = t.id;

							return (
								<AuthedDepositeBonusToast
									onClose={closeModalAuthed}
									t={t}
									navigate={navigate}
									langUrlPrefix={langUrlPrefix}
								/>
							);
						},
						{
							duration: commonUiConfig.infinityDuration,
							className: cn(s.toastNonAuthed, s.toastNonAuthed_authed)
						}
					);

					sendAnalyticsHandler(AnalyticsEventType?.popup_show, {
						name_popup: 'Deposite bonus authed user'
					});
				}
			}
		}

		return () => clearToasts();
	}, [isAuthed, createdAt, modalType, isSidebarOpened]);

	return null;
};
