import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { EditPencil, InfoWarn } from '@components/svg';
import { Button, FormError, Input } from '@components/common/elements';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import s from './style.module.scss';
import { confirmEmail, updateCurrentUserData } from '@lib/api/user';
import { AnalyticsEventType, Settings } from '@types';
import { sendAnalyticsHandler } from '@utils';

type PropsType = {
	codeOpenModalHandler: (type: 'phone' | 'email') => void;
	counter: number;
	setCounter: React.Dispatch<React.SetStateAction<number>>;
};

const EmailBlock = (props: PropsType) => {
	const { codeOpenModalHandler, counter, setCounter } = props;

	const { email, isVerified } = useSelector(userSelectors.userInfo);

	const [isEdit, setIsEdit] = useState(false);
	const [isPending, setIsPending] = useState(false);
	const [isClicked, setIsClicked] = useState(false);

	const isButtonDisabled = counter === 0 || counter === 70;

	const editHandler = () => setIsEdit((e) => !e);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Profile'
	});

	const handleSubmit = async (values: { email: string }) => {
		setIsPending(true);
		sendAnalyticsHandler(AnalyticsEventType?.profile_confirm_mail);
		const successFunc = async () => {
			codeOpenModalHandler('email');
			setIsPending(false);
			await confirmEmail();
		};

		await updateCurrentUserData(Settings.email, values?.email, successFunc);
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.email(localizeText('wrong_format'))
			.required(localizeText('can_not_be_empty'))
	});

	const formik = useFormik({
		initialValues: {
			email: email || ''
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		if (email) {
			formik?.setFieldValue('email', email);
		}
	}, [email]);

	useEffect(() => {
		let timer: NodeJS.Timeout | undefined;

		if (isClicked && counter > 0) {
			timer = setTimeout(() => setCounter(counter - 1), 1000);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [counter, isClicked]);

	return (
		<div className={s.block}>
			<div className={s.formBlock}>
				<form
					onSubmit={formik.handleSubmit}
					className={s.form}
				>
					<Input
						name='email'
						type='email'
						value={formik?.values?.email}
						label='E-mail'
						onChange={formik.handleChange}
						error={!!formik?.touched?.email && Boolean(formik?.errors?.email)}
						isDisabled={isVerified && !isEdit}
					/>

					{!isVerified || isEdit ? (
						<Button
							rippleAnimation
							handle={() => setIsClicked(true)}
							className={s.button}
							btnType='submit'
							text={localizeText('confirm')}
							isDisabled={
								!isButtonDisabled ||
								isPending ||
								(isVerified && formik?.values?.email === email)
							}
						/>
					) : (
						<button
							onClick={() => {
								editHandler();

								sendAnalyticsHandler(AnalyticsEventType?.profile_edit_mail);
							}}
							type='button'
							className={s.editBtn}
						>
							<EditPencil />
						</button>
					)}
				</form>

				<FormError
					error={formik?.errors?.email}
					isTouched={!!formik?.touched?.email}
				/>
			</div>

			{!isVerified ? (
				<div className={s.needConfirm}>
					<InfoWarn />
					{localizeText('need_confirm_email')}
				</div>
			) : (
				!isEdit && (
					<div className={s.confirmed}>
						<InfoWarn />
						{localizeText('email_confirmed')}
					</div>
				)
			)}
		</div>
	);
};

export default EmailBlock;
