import { pathsConfig } from '@config/paths';
import { AnalyticsRedirectValuesE, AnalyticsTestsNameE } from '@types';
import { valueOfYandexMetrikaTest } from '@utils';

const yandexMetricaRedirectValue = valueOfYandexMetrikaTest(
	AnalyticsTestsNameE?.REDIRECT_LINK
);

export const redirectLinkSignUp = (isSignUp: boolean): string => {
	let link = pathsConfig.home.link;

	if (
		(isSignUp && yandexMetricaRedirectValue === AnalyticsRedirectValuesE?.PAYMENT) ||
		(isSignUp && !yandexMetricaRedirectValue)
	) {
		link = pathsConfig.payment.link;
	}

	return link;
};
