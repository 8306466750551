import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocales } from '@lib/hooks';
import { getImageByURL, getWaggeringGames } from '@utils';
import { CloseSvg } from '@components/svg';
import { userSelectors } from '@store/reducers/user/selectors';
import { IProviderGame, ProviderGamesType } from '@types';
import { CardPreloader, ProviderGameCard } from '@components/common/elements';
import s from './style.module.scss';
import cn from 'classnames';
import { createModalHook, Modal, TModalProps } from '@components/shared/modal';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';

const WageringGamesModal = (props: TModalProps) => {
	const { onClose } = props;
	const { bonusBalance } = useSelector(userSelectors.userInfo);
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'WageringGamesModal'
	});

	const [wageringGames, setWageringGames] = useState<IProviderGame[]>();

	useEffect(() => {
		if (bonusBalance) {
			getWaggeringGames({ setWageringGames });
		}
	}, [bonusBalance]);

	return (
		<Modal.Root>
			<Modal.Content onClose={onClose} isMobile={isMobile} isVisibleHeader >
				<div className={s.wrapper}>
					<div className={s.header}>
						<div className={s.headerWrapper}>
							<div className={s.headerTitle}>
								<div className={cn(s.titleImage)}>
									<img src={getImageByURL('../../../../../assets/img/modals/wageringGamesModalHeader.webp')} alt="" />
								</div>
								{localizeText('games_for_bonus')}

							</div>
							<div
								onClick={onClose}
								className={s.headerClose}
							>
								<CloseSvg />
							</div>
						</div>
					</div>

					<div className={s.description}>{localizeText('description')}</div>

					<div className={s.games}>
						{!wageringGames?.length && (
							<CardPreloader length={6} />
						)}
						{!!wageringGames?.length && (
							wageringGames.map((game) => (
								<ProviderGameCard
									key={game.key}
									className={s.gamesViewerCard}
									providerName={game.providerName}
									route={game.key}
									name={game.name}
									img={game.image}
									onlyImage={true}
									isFavorite={game?.isFavourite}
									id={game?.id}
									providerGamesType={ProviderGamesType.providerGames}
									isDemo={game?.isDemo}
									isCloseModal
									onClose={onClose}
									isFreezeBalancePlay={game?.isFreezeBalancePlay}
								/>
							))
						)}
					</div>
				</div>
			</Modal.Content>
		</Modal.Root>
	);
};

export const useWageringGamesModal = createModalHook<TModalProps>(props => <WageringGamesModal {...props} />);
