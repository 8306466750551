import React, { useState } from 'react';
import s from './style.module.scss';
import { GoTopArrowSvg } from '@components/svg';
import { Ripple } from '@components/common/elements';
import cn from 'classnames';
import { useScrollPosition } from '@lib/hooks';
import { useBonusWidgetLocation } from '@lib/hooks/useBonuseWidgetLocation';
import { EYandexMetrikaVariants } from '@types';

export const ScrollTopArrow = () => {
	const clickHandler = () => {
		const body = document.querySelector('body');

		if (body) {
			window?.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	};

	const { bonusWidgetLocation } = useBonusWidgetLocation();

	const [hideOnScroll, setHideOnScroll] = useState(false);

	useScrollPosition(
		({ currPos }) => {
			const isHide = currPos.y < -100;

			setHideOnScroll(isHide);
		},
		[hideOnScroll]
	);

	return (
		<button
			onClick={clickHandler}
			className={cn(s.wrapper, {
				[s.wrapper__left]: bonusWidgetLocation === EYandexMetrikaVariants.control,
				[s.wrapperActive]: hideOnScroll
			})}
		>
			<GoTopArrowSvg />

			<Ripple />
		</button>
	);
};
