import React from 'react';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { sendAnalyticsHandler } from '@utils';
import { useLocales, useUrlLang } from '@lib/hooks';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/common/elements';
import { authSelectors } from '@store/reducers/auth/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { useSelector } from 'react-redux';
import { userSelectors } from '@store/reducers/user/selectors';
import s from './style.module.scss';
import { pathsConfig } from '@config/paths';

export const AviatorBanner = () => {
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { balance, bonusBalanceEnableValue, bonusBalance } = useSelector(
		userSelectors.userInfo
	);
	const { langUrlPrefix } = useUrlLang();

	const navigate = useNavigate();

	const { localizeText } = useLocales({
		path: 'components/common/sections',
		node: 'ExtraOffers'
	});

	const goAviatorGameHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.advertising_click_banner, {
			banner_place: 'главная карусель',
			banner_name: 'aviator',
			banner_position: 6
		});

		if (
			isAuthed &&
			balance <= bonusBalanceEnableValue &&
			bonusBalance <= bonusBalanceEnableValue
		) {
			navigate(langUrlPrefix + pathsConfig.payment.link);

			return;
		}

		if (isAuthed) {
			navigate(langUrlPrefix + '/games/provider/spribe-aviator');

			return;
		}

		setModalType(ModalType.auth);
	};

	return (
		<div
			onClick={goAviatorGameHandler}
			className={s.wrapper}
		>
			<div className={s.container}>
				<span className={s.title}>{localizeText('aviator_banner_text')}</span>

				<Button
					type={ButtonType.primary}
					text={localizeText('play')}
					className={s.btn}
					rippleAnimation={false}
					withoutGlare
					handle={goAviatorGameHandler}
				/>
			</div>
		</div>
	);
};
