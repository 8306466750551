import React, { useState } from 'react';
import s from './style.module.scss';
import Div100vh from 'react-div-100vh';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { CloseSvg } from '@components/svg';
import { Button } from '@components/common/elements';
import { ActiveBonusesWidget, NotActiveBonusesWidget } from './parts';
import { userSelectors } from '@store/reducers/user/selectors';
import { ButtonType } from '@types';
import { useLocales } from '@lib/hooks';

export const BonusWidgetModal = () => {
	const { mobileHeight, isAndroidBanner } = useSelector(commonUISelectors.commonUIInfo);
	const { bonusBalance } = useSelector(userSelectors.userInfo);
	const closeModalOutside = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setModalType(null);
		}
	};
	const [bonusCounter, setBonusCounter] = useState(0);

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'BonusWidgetModal'
	});

	const updateBonusCounter = (count: number) => {
		setBonusCounter(count);
	};

	const closeModalHandler = () => {
		setModalType(null);
	};

	return (
		<Div100vh
			onClick={closeModalOutside}
			className={cn(s.modalWrapper)}
		>
			<div
				className={s.inner}
				style={{
					maxHeight: `${isAndroidBanner ? mobileHeight - 64 : mobileHeight})}px`,
					height: '100%'
				}}
			>
				<div className={s.header}>
					<div className={s.headerTitle}>
						{localizeText('available_bonus_count', {
							amount: bonusCounter
						})}
					</div>
					<div
						onClick={closeModalHandler}
						className={s.headerClose}
					>
						<CloseSvg />
					</div>
				</div>
				<div className={s.scrollableWrapper}>
					{!!bonusBalance && <ActiveBonusesWidget />}

					<NotActiveBonusesWidget updateBonusCounter={updateBonusCounter} />

					<div className={s.bottomButtonsWrapper}>
						<Button
							type={ButtonType.primary}
							handle={closeModalHandler}
							rippleAnimation
							text={localizeText('all_bonuses')}
						/>
					</div>
				</div>
			</div>
		</Div100vh>
	);
};
