import React, { useState } from 'react';
import { getImageByURL } from '@utils';
import s from './style.module.scss';
import cn from 'classnames';
import { EYandexMetrikaVariants, ModalType } from '@types';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { useBonusWidgetLocation } from '@lib/hooks/useBonuseWidgetLocation';

export const BonusWidget = () => {
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const [counter, setCounter] = useState(0);

	const { bonusWidgetLocation } = useBonusWidgetLocation();

	const handleAddCounter = () => {
		setCounter(counter + 1);
	};

	const bonusWidgetModalClickHandler = () => {
		setModalType(ModalType.bonusWidgetModal);
	};

	return (
		<>
			<div
				className={cn(s.bonusWidgetWrapper)}
				onClick={bonusWidgetModalClickHandler}
			>
				<div
					className={cn(s.imageWrapper, {
						[s.imageWrapperLeft]:
							bonusWidgetLocation === EYandexMetrikaVariants.side && isMobile
					})}
				>
					<div
						className={cn(s.counterWrapper, {
							[s.counterWrapperLeft]:
								bonusWidgetLocation === EYandexMetrikaVariants.side && isMobile
						})}
					>
						{counter}
						<button onClick={handleAddCounter}></button>
					</div>
					<img
						src={getImageByURL('../../../../../assets/img/common/bonusWidget.webp')}
					></img>
				</div>
			</div>
		</>
	);
};
