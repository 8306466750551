import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { AnalyticsEventType, ButtonType, ModalType, EUrlQueries } from '@types';
import { Button, FormError, Input, Preloader } from '@components/common/elements';
import { CloseSvg } from '@components/svg';
import { useFormik } from 'formik';
import { getStringQueryFromUrl, sendAnalyticsHandler } from '@utils';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { isTapX, isTopXGo } from '@config/global';
import s from './style.module.scss';
import cn from 'classnames';
import * as Yup from 'yup';
import 'yup-phone-lite';
import { confirmPassword } from '@lib/api/auth';
import toast from 'react-hot-toast';
import { ErrorToast } from '@components/common/global-settings';
import Div100vh from 'react-div-100vh';
import { getImageByURL } from '@utils/getImageByURL';

type RenewPasswordValues = {
	code?: string;
	password: string;
	newPassword: string;
};

export const RenewPasswordModal = () => {
	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'RenewModal'
	});

	const notificationsLocalize = useLocales({
		path: 'global',
		node: 'notifications'
	});

	const authLocalize = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const { mobileHeight, isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const [isSuccess, setIsSuccess] = useState(false);

	useEffect(() => {
		sendAnalyticsHandler(AnalyticsEventType?.new_password_creat_modal_show);
	}, []);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const closeRenewPasswordModal = () => {
		setModalType(null);
		setIsSuccess(false);
	};

	useEffect(() => {
		setModalType(ModalType.renewPassword);
	}, []);

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.min(6, 'invalid_password_length')
			.max(40, 'invalid_password_length')
			.required('need_fill_field')
			.test('passwords-match', 'error', function(value) {
				return value === this.parent?.newPassword;
			}),

		newPassword: Yup.string()
			.min(6, 'invalid_password_length')
			.max(40, 'invalid_password_length')
			.required('need_fill_field')
			.test('passwords-match', 'error', function(value) {
				return value === this.parent?.password;
			})
	});

	const code = getStringQueryFromUrl(EUrlQueries.code);

	const handleSubmit = async (values: RenewPasswordValues): Promise<void> => {
		if (formik.isValid) {
			try {
				setIsLoading(true);

				const disableLoader = () => {
					setModalType(null);
					setIsLoading(false);
				};

				if (!code) {
					disableLoader();
					toast((t) => ErrorToast({ t: t, text: 'failed_password_update' }));

					return;
				}

				await confirmPassword(
					{
						code: code ?? undefined,
						newPassword: values.password,
						newPasswordConfirm: values.newPassword
					},

					successFunc,
					disableLoader
				);
			} catch (error) {
				toast((t) => ErrorToast({ t: t, text: 'failed_password_update' }));

				console.error('Error while trying update password');
				setModalType(null);
			}
		}
	};

	const successFunc = () => {
		setIsSuccess(true);
	};

	const formik = useFormik({
		initialValues: {
			password: '',
			newPassword: ''
		},
		validateOnChange: false,
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	const heightStyle = {
		'--height': isMobile ? mobileHeight + 'px' : 'auto'
	} as React.CSSProperties;

	return (
		<div className={cn(s.wrapper, { [s.isTopXGo]: isTopXGo || isTapX })}>
			<Div100vh className={cn(s.inner, { [s.isTopXGo]: isTopXGo || isTapX })}>
				<div className={s.headerBlock}>
					<div className={s.header}>
						<div className={s.headerWrapper}>
							<div className={s.headerTitle}>
								<span>{localizeText('renew_password')}</span>
							</div>
							<div
								onClick={closeRenewPasswordModal}
								className={s.headerClose}
							>
								<CloseSvg />
							</div>
						</div>
					</div>

					<div
						style={heightStyle}
						className={cn(s.tabs, { [s.isTopXGo]: isTopXGo || isTapX })}
					>
						{isSuccess ? (
							<>
								<div className={s.successBlock}>
									<img
										src={getImageByURL('../../../../../../assets/img/auth/reset-pass-success.webp')}
										alt='Success Reset Password'
										className={s.img}
									/>

									<span className={s.successBlock_text}>
										{notificationsLocalize?.localizeText('success_password_update')}
									</span>
								</div>

								<Button
									handle={closeRenewPasswordModal}
									type={ButtonType.primary}
									btnType='button'
									className={cn(s.button, s.buttonChangePass)}
									rippleAnimation
									text={authLocalize?.localizeText('continue')}
								/>
							</>
						) : (
							<>
								<div className={cn(s.helperPasswordTitle)}>
									{localizeText('renew_password_description')}
								</div>
								<form
									onSubmit={formik.handleSubmit}
									className={s.form}
								>
									<div className={cn(s.passInput)}>
										<Input
											name='password'
											type='password'
											label={localizeText('enter_new_password')}
											value={formik?.values?.password}
											onChange={formik.handleChange}
											onFocus={() => {
												formik.setFieldError('password', '');
												formik.setFieldError('newPassword', '');
											}}
											error={
												!!formik?.touched?.password && Boolean(formik?.errors?.password)
											}
										/>
										<FormError
											error={
												formik?.errors?.password && localizeText(formik?.errors?.password)
											}
											isTouched={!!formik?.touched?.password}
										/>
									</div>

									<div className={cn(s.passInput)}>
										<Input
											name='newPassword'
											type='password'
											label={localizeText('enter_new_repeated_password')}
											value={formik?.values?.newPassword}
											onChange={formik.handleChange}
											onFocus={() => {
												formik.setFieldError('password', '');
												formik.setFieldError('newPassword', '');
											}}
											error={
												!!formik?.touched?.newPassword &&
												Boolean(formik?.errors?.newPassword)
											}
										/>
										<FormError
											error={
												formik?.errors?.newPassword &&
												localizeText(formik?.errors?.newPassword)
											}
											isTouched={!!formik?.touched?.newPassword}
										/>
									</div>
									<Button
										type={ButtonType.primary}
										btnType='submit'
										rippleAnimation
										className={cn(s.button, s.buttonChangePass)}
										isDisabled={isLoading}
										handle={() =>
											sendAnalyticsHandler(
												AnalyticsEventType?.new_password_creat_modal_click
											)
										}
									>
										{isLoading ? (
											<Preloader
												styles={{ height: '100%', marginTop: 10, marginBottom: 10 }}
												isWhite={true}
											/>
										) : (
											localizeText('confirm')
										)}
									</Button>
								</form>
								{formik.errors.newPassword === 'error' && (
									<div className={cn(s.error)}>
										{localizeText('passwords_not_matching')}
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</Div100vh>
		</div>
	);
};
