import React, { useEffect, useState } from 'react';
import { AmountInput } from '../../parts';
import { Button, Ripple } from '@components/common/elements';
import { paymentInvoice } from '@lib/api/payment';
import { setUserConfirmedPayment } from '@store/reducers/payment/dispathcers';
import { CardP } from '../../../../../../type';
import {
	AnalyticsEventType,
	ButtonType,
	Currencies,
	InvoicePaymentResponseDataType,
	PaymentFlows,
	PaymentType,
	PaymentUIType,
	Roles
} from '@types';
import { VideoInstructionIcon } from '@components/svg';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { formatNumber, renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { userSelectors } from '@store/reducers/user/selectors';
import { AdditionalFelds } from '../../../additional_fields';
import s from './style.module.scss';
import convertToUsers from '@utils/convertToUsers';
import toast from 'react-hot-toast';
import { ErrorToast } from '@components/common/global-settings';

type InvoiceDataType = {
	amount: number;
	responseType?: PaymentUIType;
	fields: Record<string, unknown>;
	isTelegram?: boolean;
	currency: Currencies;
	userRole: Roles;
	currentPaymentError?: string;
	botTelegramId?: string;
};

export const Card = (props: CardP) => {
	const {
		invoiceValue,
		localizeText,
		currentPaymentMethod,
		setInnerPlatformFlow,
		setDataForPay,
		calculateFSBonusHandler,
		innerPlatformFlow,
		paymentType,
		openVideoHandler,
		doubleDeposBonus
	} = props;

	const { isMobile, isTelegram } = useSelector(commonUISelectors.commonUIInfo);
	const { currency, role, botTelegramId } = useSelector(userSelectors.userInfo);

	const [walletData, setWalletData] = useState({});

	const [amountError, setAmountError] = useState('');

	const [isAddFieldsModal, setIsAddFieldsModal] = useState(false);

	const [isError, setIsError] = useState(false);
	const [errors, setErrors] = useState<Record<string, string | undefined>>({});

	const openAdditionalFieldsModal = () => {
		setIsAddFieldsModal(!isAddFieldsModal);
	};

	const paymentHandler = () => {
		const successFunc = (data?: InvoicePaymentResponseDataType) => {
			if (
				currentPaymentMethod?.name === 'Nagad' &&
				currentPaymentMethod?.responseType !== PaymentUIType.redirect
			) {
				setInnerPlatformFlow(PaymentFlows.flow_nagad);
			}

			if (currentPaymentMethod?.responseType === PaymentUIType.native_confirm) {
				setInnerPlatformFlow(PaymentFlows.native_confirm);
			}

			if (data) {
				setDataForPay(data);
			}
		};

		setUserConfirmedPayment(true);

		const data: InvoiceDataType = {
			amount: +invoiceValue * 100,
			responseType: currentPaymentMethod?.responseType,
			fields: {},
			isTelegram: isTelegram,
			currency: currency,
			userRole: role,
			botTelegramId: botTelegramId
		};

		const sendData = () => {
			const fields = currentPaymentMethod?.settings?.fields?.map((f) => f?.field);

			if (fields) {
				fields?.forEach((d) => {
					if (data.fields) {
						const value = walletData[d as keyof typeof walletData] as string;

						data.fields[d] = typeof value === 'string' ? value.trim() : value;
					}
				});
			}
		};

		const fields = currentPaymentMethod?.settings?.fields || [];
		let isHasEmptyField = false;
		let isHasNotValidatedField = false;

		fields.forEach((d) => {
			const reg = new RegExp(d?.validator?.regex || '', 'g');
			const fieldValue = walletData[d?.field as keyof typeof walletData];

			if (!reg.test(fieldValue)) {
				errors[d?.field] = d?.validator?.errorMessage || 'Invalid input';
				setIsError(true);
				isHasNotValidatedField = true;
			}

			if (!fieldValue) {
				isHasEmptyField = true;
			}
		});

		if (isHasEmptyField) {
			toast((t) => ErrorToast({ t: t, text: 'withdrawal_all_fields_must_be_filled' }));

			return;
		}

		if (isHasNotValidatedField) {
			return;
		}

		if (isAddFieldsModal) {
			openAdditionalFieldsModal();
		}

		if (!isError) {
			sendData();
			paymentInvoice(data, successFunc);
		}
	};

	useEffect(() => {
		setAmountError('');
	}, [invoiceValue]);

	const checkPayHandler = () => {
		if (
			!invoiceValue ||
			currentPaymentMethod?.settings?.minInvoice > Number(invoiceValue) * 100 ||
			currentPaymentMethod?.settings?.maxInvoice < Number(invoiceValue) * 100
		) {
			if (!invoiceValue) {
				setAmountError(localizeText('amount_must_be_entered'));
			}

			if (currentPaymentMethod?.settings?.minInvoice > Number(invoiceValue)) {
				setAmountError(
					localizeText('min_amount', {
						amount: formatNumber(
							convertToUsers(currentPaymentMethod?.settings?.minInvoice)
						),
						cur: renderCurrencySymbol(currency)
					})
				);
			}

			if (currentPaymentMethod?.settings?.maxInvoice < Number(invoiceValue)) {
				setAmountError(
					localizeText('max_amount', {
						amount: formatNumber(
							convertToUsers(currentPaymentMethod?.settings?.maxInvoice)
						),
						cur: renderCurrencySymbol(currency)
					})
				);
			}

			return;
		}

		if (
			currentPaymentMethod?.settings &&
			currentPaymentMethod?.settings?.fields &&
			currentPaymentMethod?.settings?.fields?.length > 0
		) {
			openAdditionalFieldsModal();
		} else {
			paymentHandler();
		}
	};

	return (
		<>
			<div className={s.invoiceBlock}>
				{!isMobile && <div className={s.enterAmount}>{localizeText('enter_amount')}</div>}

				<AmountInput
					invoiceValue={invoiceValue}
					localizeText={localizeText}
					calculateFSBonusHandler={calculateFSBonusHandler}
					amountError={amountError}
					doubleDeposBonus={doubleDeposBonus}
				/>

				<Button
					type={ButtonType?.primary}
					wrapperClass={s.btnWrapper}
					className={s.btn}
					text={
						currentPaymentMethod?.settings?.fields &&
						currentPaymentMethod?.settings?.fields?.length > 0
							? localizeText('continue')
							: localizeText('deposit')
					}
					handle={() => {
						checkPayHandler();
						sendAnalyticsHandler(AnalyticsEventType?.payment_click_topup);
					}}
					withoutGlare={true}
					rippleAnimation
				/>

				{(!innerPlatformFlow || !isMobile) &&
					currentPaymentMethod?.settings?.videoUrl &&
					paymentType === PaymentType.invoice && (
						<div
							onClick={() => {
								openVideoHandler && openVideoHandler();
								sendAnalyticsHandler(AnalyticsEventType?.payment_click_instruction);
							}}
							className={s.videoBlock}
						>
							<div className={s.videoBlock_btn}>
								<VideoInstructionIcon />
								<span className={s.videoBlock_text}>{localizeText('how_refill')}</span>
								<Ripple />
							</div>
							<div className={s.videoBlock_tip}>{localizeText('video_instruction')}</div>
						</div>
					)}
			</div>

			{currentPaymentMethod?.settings &&
				currentPaymentMethod?.settings?.fields &&
				currentPaymentMethod?.settings?.fields?.length > 0 &&
				isAddFieldsModal && (
					<AdditionalFelds
						errors={errors}
						setErrors={setErrors}
						setIsError={setIsError}
						openAdditionalFieldsModal={openAdditionalFieldsModal}
						fields={currentPaymentMethod?.settings?.fields}
						setWalletData={setWalletData}
						walletData={walletData}
						description={currentPaymentMethod?.settings?.description}
						paymentHandler={paymentHandler}
						providerId={currentPaymentMethod?.id}
					/>
				)}
		</>
	);
};
