import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Div100vh from 'react-div-100vh';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import cn from 'classnames';

import { renderCurrencySymbol } from '@utils';
import { getImageByURL } from '@utils/getImageByURL';

import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { userSelectors } from '@store/reducers/user/selectors';

import { AccordionItem, Button } from '@components/common/elements';

import { ButtonType } from '@types';

import s from './style.module.scss';

import {
	CloseSvg,
	IconModal_1,
	IconModal_2,
	IconModal_3,
	IconModal_4
} from '@components/svg';

interface AccordionItemData {
	title: string;
	content: string;
}

export const BonusesModal = () => {
	const { modalType, mobileHeight, isAndroidBanner, isMobile } = useSelector(
		commonUISelectors.commonUIInfo
	);
	const { currency } = useSelector(userSelectors.userInfo);
	const currencySymbol = renderCurrencySymbol(currency?.toLowerCase());

	const { t } = useTranslation('components/common/modals/translation', {
		useSuspense: false
	});

	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, []);

	const renderNumberImage = (index: number) => {
		const icons = [<IconModal_1 />, <IconModal_2 />, <IconModal_3 />, <IconModal_4 />];

		return icons[index] || null;
	};

	const renderTranslation = (index: number, accordionTitle: string, item: string) => (
		<Trans
			ns='components/common/modals/translation'
			i18nKey={`${modalType}.${accordionTitle}.${index}.${item}`}
			values={{
				curr: currencySymbol
			}}
			components={{
				1: <span className={s.spanWager} />,
				2: <span className={s.spanWager} />,
				3: <span className={s.spanWager} />
			}}
		/>
	);

	const closeModalOutside = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setModalType(null);
		}
	};

	const accordionItems: AccordionItemData[] = useMemo(() => {
		return (
			(t(`${modalType}.accordionItems`, {
				returnObjects: true
			}) as AccordionItemData[]) || []
		);
	}, [modalType, t]);

	const accordionPopularQuestionsItems: AccordionItemData[] = useMemo(() => {
		return (
			(t(`${modalType}.popularQuestionsItems`, {
				returnObjects: true
			}) as AccordionItemData[]) || []
		);
	}, [modalType, t]);

	if (!accordionItems || !accordionPopularQuestionsItems) return null;

	return (
		<Div100vh
			onClick={closeModalOutside}
			className={cn(s.modalWrapper)}
		>
			<div
				className={s.inner}
				style={{
					maxHeight: `${isAndroidBanner ? mobileHeight - 64 : mobileHeight})}px`,
					height: '100%'
				}}
			>
				<div className={s.bonusModalWrapper}>
					<button
						onClick={() => setModalType(null)}
						className={s.closeIcon}
					>
						<CloseSvg />
					</button>
					<img
						className={s.bonusModalImg}
						src={getImageByURL(`../../../../../assets/img/modals/${modalType}.webp`)}
						alt='Modal'
					/>
				</div>
				<div
					className={s.scrollableWrapper}
					style={{
						maxHeight: `${mobileHeight - 160 - (!isMobile ? 80 : isAndroidBanner ? 64 : 0)}px`,
						height: '100%',
						overflow: 'auto'
					}}
				>
					<div className={s.main_info}>
						<span>{t(`${modalType}.modalTitle`)}</span>
						<span>{t(`${modalType}.modalDescription`)}</span>
					</div>

					<div className='accordion'>
						{accordionItems?.map((_, index) => (
							<AccordionItem
								key={index}
								image={renderNumberImage(index)}
								title={renderTranslation(index, 'accordionItems', 'title')}
								content={renderTranslation(index, 'accordionItems', 'content')}
							/>
						))}
					</div>

					<div className={s.popularQuestions}>
						<span>{t('popularQuestions')}</span>
					</div>

					<div className='accordion'>
						{accordionPopularQuestionsItems?.map((_, index) => (
							<AccordionItem
								key={index}
								title={renderTranslation(index, 'popularQuestionsItems', 'title')}
								content={renderTranslation(index, 'popularQuestionsItems', 'content')}
							/>
						))}
					</div>

					<div className={s.bottomButtonsWrapper}>
						<Button
							handle={() => setModalType(null)}
							rippleAnimation
							type={ButtonType.secondary}
							text={t('close_button')}
						/>
					</div>
				</div>
			</div>
		</Div100vh>
	);
};
