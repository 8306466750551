import React from 'react';

import { InvoiceBonusBlock, OtherBonuses } from './parts';
import { CashbackBlock } from '../level-bonus/parts';

import s from './style.module.scss';

const AllBonus = () => {
	return (
		<>
			<InvoiceBonusBlock />

			<div className={s.divider} />

			<CashbackBlock />

			<div className={s.divider} />

			<OtherBonuses />
		</>
	);
};

export default AllBonus;
