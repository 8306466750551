import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ModalsResolver } from './ModalsResolver';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { setIsSidebarOpened, setModalType } from '@store/reducers/common-ui/dispatchers';
import s from './style.module.scss';
import Div100vh from 'react-div-100vh';
import cn from 'classnames';
import { ModalType } from '@types';

export const ModalsWrapper = () => {
	const { modalType, isMobile, isAndroidBanner } = useSelector(
		commonUISelectors.commonUIInfo
	);

	useEffect(() => {
		setIsSidebarOpened(false);
	}, [modalType]);

	useEffect(() => {
		const body = document.querySelector('body');

		if (modalType && body) {
			body.style.overflow = 'hidden';
		} else if (body) {
			body.style.overflow = 'auto';
		}
	}, [modalType]);

	const isVisibleHeader =
		isMobile &&
		modalType &&
		[
			ModalType.bonusDepositModal,
			ModalType.freespinsModal,
			ModalType.promoBonusModal,
			ModalType.cashbackModal,
			ModalType.levelUpModal,
			ModalType.tgBonusModal,
			ModalType.referralBonusModal,
			ModalType.appInstallationBonusModal,
		].includes(modalType);

	return (
		<div
			className={cn(s.outer, {
				[s.active]:
					modalType && modalType !== ModalType?.signIn && modalType !== ModalType?.auth,
				[s.androidBanner]: isAndroidBanner,
				[s.visibleHeader]: isVisibleHeader
			})}
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) {
					setModalType(null);
				}
			}}
		>
			{isMobile ? (
				<Div100vh className={s.inner}>
					<ModalsResolver modalType={modalType} />
				</Div100vh>
			) : (
				<div className={s.inner}>
					<ModalsResolver modalType={modalType} />
				</div>
			)}
		</div>
	);
};
