import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { AnalyticsEventType, AnalyticsTestsNameE, RegisterPageBannerValue } from '@types';
import { GreenCheckIconSvg } from '@components/svg';
import { sendAnalyticsHandler, valueOfYandexMetrikaTest } from '@utils';
import s from './style.module.scss';

export const BannersSignUp_1_2 = () => {
	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const [bannerValue, setBannerValue] = useState('');

	useEffect(() => {
		const bannerTest = valueOfYandexMetrikaTest(AnalyticsTestsNameE?.SIGNUP_BANNERS);

		setBannerValue(bannerTest || RegisterPageBannerValue?.MAIN);
	}, []);

	const renderBanner = () => {
		switch (bannerValue) {
			case RegisterPageBannerValue.MAIN:
				return (
					<div className={s.bonus}>
						<img
							alt='bonus'
							src='/assets/img/common/register_bonus.webp'
							className={s.bonusIcon}
						/>
						<div className={s.bonusContent}>
							<div className={s.bonusTitle}>{localizeText('double_deposite')}</div>
							<div className={s.bonusDescription}>{localizeText('register_today')}</div>
						</div>
						<GreenCheckIconSvg className={s.bonusCheck} />
					</div>
				);
			case RegisterPageBannerValue.V1:
				return (
					<div className={s.bonusV}>
						<img
							alt='bonusV1'
							src='/assets/img/common/register_bonus_V1.webp'
							className={s.bonusVIcon}
						/>
						<div className={s.bonusVContent}>
							<div className={s.bonusVTitle1}>{localizeText('depositx5')}</div>
							<div className={s.bonusVDescriptionV1}>
								{localizeText('depositx5descr')}
							</div>
						</div>
					</div>
				);
			case RegisterPageBannerValue.V2:
				return (
					<div className={s.bonusV}>
						<img
							alt='bonusV2'
							src='/assets/img/common/register_bonus_V2.webp'
							className={s.bonusVIcon}
						/>
						<div className={s.bonusVContent}>
							<div className={s.bonusVTitle2}>{localizeText('deposit30k')}</div>
							<div className={s.bonusVDescriptionV2}>
								{localizeText('deposit30kdescr')}
							</div>
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div
			onClick={() => {
				sendAnalyticsHandler(AnalyticsEventType?.advertising_click_banner, {
					banner_place: 'Форма регистрации',
					banner_name: 'Double deposite small',
					banner_position: 1
				});
			}}
			className={s.bonusWrapper}
		>
			{renderBanner()}
		</div>
	);
};
