import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Countdown from 'react-countdown';
import Div100vh from 'react-div-100vh';
import cn from 'classnames';
import dayjs from 'dayjs';

import { setBonusBalancesCancel } from '@lib/api/bonus';
import { formatNumber, renderCurrencySymbol } from '@utils';
import { getImageByURL } from '@utils/getImageByURL';
import convertToUsers from '@utils/convertToUsers';
import { useLocales } from '@lib/hooks';
import { useWageringGamesModal } from '@components/common/modals/wagering-games-modal';

import { Button } from '@components/common/elements';
import { ProgressBar } from '@components/common/elements/progress-bar';
import { CloseSvg, DeclineBonus, InfoWarn } from '@components/svg';

import { userSelectors } from '@store/reducers/user/selectors';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';

import { ButtonType, ModalType } from '@types';
import { EBonusBalanceVariants } from '../../type';

import s from './style.module.scss';
import 'react-circular-progressbar/dist/styles.css';

type DataType = {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	formatted: { days: string; hours: string; minutes: string; seconds: string };
};

export const BonusBalancesBlock = () => {
	const { bonusBalances } = useSelector(bonusSelectors.bonusInfo);
	const { bonusBalanceWager } = useSelector(userSelectors.userInfo);
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'AllBonus'
	});

	const [bonusModal, setBonusModal] = useState<boolean>(false);
	const [hideProgress, setHideProgress] = useState<boolean>(false);
	const [openWageringModal] = useWageringGamesModal();

	const bonusInfoModalHandler = () => {
		setBonusModal(!bonusModal);
	};

	const setModalTypeClickHandler = (type: string) => {
		setModalType(modalSwitcher(type));
	};

	const setWageringGamesModalTypeClickHandler = () => {
		openWageringModal({});
	};

	const renderer = (data: DataType) => {
		const { formatted } = data;

		const { hours, minutes, seconds } = formatted;

		return (
			<div className={s.counter}>
				<span>{localizeText('running_out_in')}</span>
				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{hours}</div>
					</div>
				</div>
				:
				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{minutes}</div>
					</div>
				</div>
				:
				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{seconds}</div>
					</div>
				</div>
			</div>
		);
	};

	const declineBonusHandler = () => {
		setBonusBalancesCancel();
		bonusInfoModalHandler();

		setHideProgress(true);

		setTimeout(() => {
			setHideProgress(false);
		}, 2000);
	};

	const modalSwitcher = (type: string) => {
		switch (type) {
			case EBonusBalanceVariants.DEPOSIT:
				return ModalType.bonusDepositModal;
			case EBonusBalanceVariants.CASHBACK:
				return ModalType.cashbackModal;
			case EBonusBalanceVariants.PROMO:
				return ModalType.promoBonusModal;
			case EBonusBalanceVariants.FREESPIN_WIN:
				return ModalType.freespinsModal;
			case EBonusBalanceVariants.LEVEL_UP:
				return ModalType.levelUpModal;
			case EBonusBalanceVariants.APP_INSTALL:
				return ModalType.appInstallationBonusModal;
			case EBonusBalanceVariants.TASK_BONUS:
				return ModalType.tgBonusModal;
			default:
				return ModalType.bonusDepositModal;
		}
	};

	return (
		<div className={s.content}>
			<div className={s.bonusBlock}>
				<div className={s.bonusList}>
					<div className={s.bonusBlock_bottom}>
						<>
							{bonusBalances
								?.filter((bonus) => bonus.status === 'active')
								?.map((balance) => {
									const { id, currency, bonusBalance, type, expiresAt } = balance;

									const wagerBonus = balance?.bonusBalanceWager;
									const currentValue = Number(
										(
											100 -
											(bonusBalanceWager * 100) / convertToUsers(wagerBonus)
										)?.toFixed(0)
									);
									const maxValue = Number(
										(100 - (bonusBalanceWager * 100) / wagerBonus)?.toFixed(0)
									);

									return (
										<>
											<div className={s.timer}>
												<div className={s.waggeringGames_titleBlock}>
													<div className={s.title}>
														<img
															className={s.icon}
															alt='Bonus icon'
															src={getImageByURL(
																'../../../../../../assets/img/common/bonus_balances_icon.webp'
															)}
														/>
														<Countdown
															date={dayjs(expiresAt).format()}
															renderer={renderer}
															daysInHours
														/>
													</div>
												</div>
											</div>

											<div
												key={id}
												className={s.invoiceItem}
											>
												<div className={cn(s.invoiceItem_top, s.active)}>
													<div
														className={s.depNum}
														onClick={() => setModalTypeClickHandler(type)}
													>
														<InfoWarn />
													</div>
													<div className={s.invoiceItem_top_content}>
														<div className={s.progressBlocK}>
															<div className={s.descrBlock}>
																<span>{localizeText(`${type}_bonus`)}</span>
																<span>
																	{renderCurrencySymbol(currency?.toLowerCase())}{' '}
																	{formatNumber(convertToUsers(bonusBalance))}
																</span>
															</div>
															{!hideProgress && (
																<>
																	<ProgressBar
																		containerClassName={s.progressBarContainer}
																		currentValue={currentValue}
																		maxValue={maxValue}
																		customBg='rgba(0, 0, 0, 0.5)'
																		isSidebarOpened={true}
																	/>

																	<div className={s.wagering_withdrawal}>
																		{localizeText('wagering_withdrawal')}
																		<div className={s.wagering_withdrawal_wrapper}>
																			{currentValue}
																			<div className={s.wagering_withdrawal_counter}>
																				/100%
																			</div>
																		</div>
																	</div>
																</>
															)}
														</div>

														<button
															onClick={bonusInfoModalHandler}
															className={s.cancelBonus}
														>
															<DeclineBonus />
															{localizeText('decline_bonus')}
														</button>
													</div>
												</div>
											</div>
											<div
												onClick={setWageringGamesModalTypeClickHandler}
												className={s.waggeringGames}
											>
												<button className={s.playForBonus}>
													{localizeText('play_for_bonus')}
												</button>
											</div>
										</>
									);
								})}
							{!!bonusBalances?.filter((bonus) => bonus.status !== 'active').length && (
								<>
									<div className={s.divider} />
									<div className={s.nextBonuses}>{localizeText('nextBonuses')}</div>
								</>
							)}

							{bonusBalances
								?.filter((bonus) => bonus.status !== 'active')
								.map((balance) => {
									const { id, currency, bonusBalance, type, isVisited } = balance;

									return (
										<>
											<div
												key={id}
												className={cn(s.invoiceItem, {
													['not-visited-bonus']: !isVisited
												})}
											>
												<div className={cn(s.invoiceItem_top)}>
													<div
														className={s.depNum}
														onClick={() => setModalTypeClickHandler(type)}
													>
														<InfoWarn />
													</div>
													<div className={s.invoiceItem_top_content}>
														<div className={s.progressBlocK}>
															<div className={s.descrBlock}>
																<span className={s.disabled}>
																	{localizeText(`${type}_bonus`)}
																</span>
																<span>
																	{renderCurrencySymbol(currency?.toLowerCase())}{' '}
																	{formatNumber(convertToUsers(bonusBalance))}
																</span>
															</div>
														</div>

														<button
															onClick={bonusInfoModalHandler}
															className={s.cancelBonus}
															disabled={true}
														>
															<DeclineBonus />
															{localizeText('decline_bonus')}
														</button>
													</div>
												</div>
											</div>
										</>
									);
								})}
						</>
					</div>
				</div>
			</div>

			<Div100vh
				onClick={(e) => {
					if (e.target === e.currentTarget) {
						bonusInfoModalHandler();
					}
				}}
				className={cn(s.cancelWrapper, { [s.show]: bonusModal })}
			>
				<div className={s.inner}>
					<button
						onClick={bonusInfoModalHandler}
						className={s.closeCancel}
					>
						<CloseSvg />
					</button>
					<div className={s.cancelDecr}>
						<span>{localizeText('decline_bonus')}</span>
						<span>{localizeText('decline_bonus_descr')}</span>
					</div>

					<div className={s.cancelBtnsBlock}>
						<Button
							handle={declineBonusHandler}
							rippleAnimation
							type={ButtonType.primary}
							text={localizeText('decline_bonus')}
						/>
						<Button
							handle={bonusInfoModalHandler}
							rippleAnimation
							type={ButtonType.secondary}
							text={localizeText('continue_play')}
						/>
					</div>
				</div>
			</Div100vh>
		</div>
	);
};
