import React, { useRef, useState } from 'react';
import { pathsConfig } from '@config/paths';
import { useNavigate } from 'react-router-dom';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Trans } from 'react-i18next';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import { userSelectors } from '@store/reducers/user/selectors';
import { Button } from '@components/common/elements';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { ModalType, ButtonType, AnalyticsEventType } from '@types';
import { CheckIcon, InfoWarn } from '@components/svg';
import s from './style.module.scss';
import cn from 'classnames';
import { sendAnalyticsHandler } from '@utils';
import { getImageByURL } from '@utils/getImageByURL';

export const InvoiceBonusBlock = () => {
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isTablet } = useSelector(commonUISelectors.commonUIInfo);
	const { successInvoiceCount } = useSelector(userSelectors.userInfo);

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'AllBonus'
	});

	const listRef = useRef<HTMLDivElement>(null);

	const [isMouseDown, setIsMouseDown] = useState(false);
	const mouseCoords = useRef({
		startX: 0,
		startY: 0,
		scrollLeft: 0,
		scrollTop: 0
	});

	const clickInfoHandler = () => setModalType(ModalType.bonusDepositModal);

	const handleDragStart = (e: { pageX: number; pageY: number }) => {
		if (!listRef.current) return;

		const slider = listRef.current as HTMLElement;

		const startX = e.pageX - slider.offsetLeft;
		const startY = e.pageY - slider.offsetTop;
		const scrollLeft = slider.scrollLeft;
		const scrollTop = slider.scrollTop;

		mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
		setIsMouseDown(true);
		document.body.style.cursor = 'grabbing';
	};
	const handleDragEnd = () => {
		setIsMouseDown(false);
		if (!listRef.current) return;

		document.body.style.cursor = 'default';
	};
	const handleDrag = (e: {
		preventDefault: () => void;
		pageX: number;
		pageY: number;
	}) => {
		if (!isMouseDown || !listRef.current) return;

		e.preventDefault();

		const slider = listRef.current as HTMLElement;
		const x = e.pageX - slider.offsetLeft;
		const y = e.pageY - slider.offsetTop;
		const walkX = (x - mouseCoords.current.startX) * 1.5;
		const walkY = (y - mouseCoords.current.startY) * 1.5;

		slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
		slider.scrollTop = mouseCoords.current.scrollTop - walkY;
	};

	const btnClickHandler = () => {
		if (isAuthed) {
			navigate(langUrlPrefix + pathsConfig?.payment?.link);
			sendAnalyticsHandler(AnalyticsEventType?.all_bonuses_click_payment);
		} else {
			setModalType(ModalType.auth);

			sendAnalyticsHandler(AnalyticsEventType?.all_bonuses_click_registration, {
				all_bonuses_block: 'Главный'
			});
		}
	};

	return (
		<div className={s.content}>
			<div className={s.bonusBlock}>
				<div className={s.bonusBlock_top}>
					<div
						id={'invoice_block'}
						className={s.titleBlock}
					>
						<span className={s.titleBlock_title}>
							<Trans
								ns='components/routes/translation'
								i18nKey={
									isAuthed
										? 'AllBonus.invoice_bonus_title_authed'
										: 'AllBonus.invoice_bonus_title'
								}
								components={{
									gold: <b className={s.gold} />,
									green: <b className={s.green} />,
									greenSmall: <b className={s.greenSmall} />
								}}
							/>
						</span>
						<span className={s.titleBlock_descr}>
							{localizeText('invoice_bonus_descr')}
						</span>
					</div>

					<img
						alt='Invoice bonuse'
						className={s.image}
						src={getImageByURL('../../../../../../assets/img/bonus/invoice_bonus.webp')}
					/>
				</div>
				<div className={s.bonusList}>
					<div
						ref={listRef}
						onMouseDown={handleDragStart}
						onMouseUp={handleDragEnd}
						onMouseMove={handleDrag}
						className={s.bonusBlock_bottom}
					>
						{Array.from({ length: 5 }, (_, i) => i + 1)?.map((num) => {
							const isActive =
								(!isAuthed && num === 1) || (isAuthed && successInvoiceCount + 1 === num);

							const isPrevious = isAuthed && num <= successInvoiceCount;

							const isFirst = num === 1;

							return (
								<div
									key={num}
									className={s.invoiceItem}
									id={isActive ? 'current-dep' : ''}
								>
									<div
										className={cn(s.invoiceItem_top, {
											[s.active]: isActive || isPrevious
										})}
									>
										<div className={cn(s.depNum, { [s.active]: isActive || isPrevious })}>
											{localizeText(`dep_${num}`)}
										</div>
										<div className={s.invoiceItem_top_content}>
											<button
												className={s.infoBtn}
												onClick={clickInfoHandler}
											>
												<InfoWarn />
											</button>
											<div className={s.descrBlock}>
												<span>
													<Trans
														ns='components/routes/translation'
														i18nKey={`AllBonus.dep_bonus_title_${num}`}
														components={{
															1: <b className={s.small} />
														}}
													/>
												</span>
												<span>{localizeText('dep_bonus_descr')}</span>
											</div>

											<div className={s.divider} />

											<div className={s.fsBlock}>
												<img
													className={s.fsImg}
													alt='FS'
													src={getImageByURL(
														'../../../../../../assets/img/common/free_spins.webp'
													)}
												/>
												+ 175 FS
											</div>
										</div>
									</div>
									<div className={s.invoiceItem_bottom}>
										<div
											className={cn(s.line, {
												[s.first]: isFirst,
												[s.active]: isActive || isPrevious,
												[s.firstActive]: isFirst && isActive,
												[s.current]: isActive
											})}
										/>
										{isActive ? (
											<Button
												className={s.invoiceItem_bottom_btn}
												type={ButtonType?.primary}
												text={
													isAuthed
														? localizeText('top_up_btn')
														: localizeText('register_btn')
												}
												handle={btnClickHandler}
												withoutGlare
											/>
										) : isPrevious ? (
											<div className={s.circleChecked}>
												<CheckIcon />
											</div>
										) : (
											<div className={s.circle} />
										)}
									</div>
								</div>
							);
						})}
					</div>

					{!isTablet && <div className={s.shadow} />}
				</div>
			</div>
		</div>
	);
};
