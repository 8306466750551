import React, { lazy, useEffect, useState } from 'react';
import { pathsConfig } from '@config/paths';
import { RoutePreRenderingWrap } from '@components/routes-wrapper';
import { getProviders } from '@lib/api/game-provider';
import { useLocales, useUrlLang } from '@lib/hooks';
import { SearchInput } from '@components/common/elements';
import { useNavigate } from 'react-router-dom';
import { gameProviderSelectors } from '@store/reducers/game-provider/selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import { CloseSvg } from '@components/svg';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { metaConfig } from '@config/meta';
import { casinoType } from '@utils/casinoType';
import { Helmet } from 'react-helmet';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';

const ProvidersBody = lazy(() => import('./Providers'));

export const Providers = () => {
	const { providers } = useSelector(gameProviderSelectors.gameProviderInfo);
	const { isUpdateGameProviders } = useSelector(commonUISelectors.commonUIInfo);

	const { langUrlPrefix } = useUrlLang();

	const initMethods: Array<Function> = [
		() => !providers?.length && getProviders({ limit: 100, page: 1 }, false)
	];

	useEffect(() => {
		if (isUpdateGameProviders) {
			getProviders({ limit: 100, page: 1 }, false);
		}
	}, [isUpdateGameProviders]);

	const navigate = useNavigate();

	const [searchValue, setSearchValue] = useState<string>('');

	const providersLoc = useLocales({
		path: 'components/providers',
		node: 'providersList'
	});

	const gamesLoc = useLocales({
		path: 'components/games',
		node: 'search'
	});

	const searchInputHandler = (text: string | number) => {
		setSearchValue(text?.toString());
	};

	const closeProvidersSearch = () => {
		sendAnalyticsHandler(AnalyticsEventType?.close_back, {
			close_back: 'providers'
		});

		setTimeout(() => navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link), 200);
	};

	const onCloseHandler = () => {
		setSearchValue('');
	};

	return (
		<>
			<Helmet>
				<title>{metaConfig.metatags[casinoType()].providers?.title}</title>
				<meta
					name='description'
					content={metaConfig.metatags[casinoType()].providers?.description}
				/>

				<link
					rel='canonical'
					href={window?.location?.href}
				/>
			</Helmet>
			<RoutePreRenderingWrap initMethods={initMethods}>
				<React.Suspense>
					<div className={s.wrapper}>
						<div className={s.searchBlock}>
							<SearchInput
								placeholder={providersLoc.localizeText('search_provider_placeholder')}
								name='search_provider'
								type='text'
								value={searchValue}
								onChange={searchInputHandler}
								onCloseHandler={onCloseHandler}
								rippleAnimation
							/>
							<button
								onClick={closeProvidersSearch}
								className={s.closeButton}
							>
								<CloseSvg />
							</button>
						</div>
						{searchValue ? (
							<h2 className={s.title}>
								{gamesLoc?.localizeText('game_searching_results')}
							</h2>
						) : (
							<h2 className={s.title}>
								{gamesLoc?.localizeText('provider_searching_recommends')}
							</h2>
						)}
						<ProvidersBody searchValue={searchValue} />
					</div>
				</React.Suspense>
			</RoutePreRenderingWrap>
		</>
	);
};
