import React from 'react';
import HomeBody from './Home';
import { metaConfig } from '@config/meta';
import { casinoType } from '@utils/casinoType';
import { Helmet } from 'react-helmet';
import { DepositeBanners, OnesignalComponent } from './parts';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';

export const Home = () => {
	const { isAuthed } = useSelector(authSelectors.authInfo);

	return (
		<>
			<Helmet>
				<title>{metaConfig.metatags[casinoType()]?.main_page?.title}</title>
				<meta
					name='description'
					content={metaConfig.metatags[casinoType()]?.main_page?.description}
				/>
				<link
					rel='canonical'
					href={window?.location?.href}
				/>
			</Helmet>

			<HomeBody />

			<DepositeBanners />
			{isAuthed && <OnesignalComponent />}
		</>
	);
};
