import { LocalizeText, SocialBonusTaskType } from '@types';

export interface IMediaBonusCard {
	title: string;
	description: any;
	bonusType: SocialBonusTaskType;
}

export type MediaBonusCardP = IMediaBonusCard & {
	localizeText: LocalizeText;
	setSelectedTask: React.Dispatch<React.SetStateAction<SocialBonusTaskType | null>>;
};

export enum EBonusBalanceVariants {
	DEPOSIT = 'deposit',
	TASK_BONUS = 'task',
	FREESPIN_WIN = 'freespin_win',
	LEVEL_UP = 'level_up',
	CASHBACK = 'cashback',
	PROMO = 'promo',
	APP_INSTALL = 'app_install'
}

export enum EBonusBalanceStatusVariants {
	EXPIRED = 'expired',
	CANCELLED = 'cancelled',
	FINISHED = 'finished',
	TRANSFERRED = 'transferred'
}
