import { AnalyticsTestsNameE, EYandexMetrikaVariants } from '@types';
import { valueOfYandexMetrikaTest } from '@utils';
import { useEffect, useState } from 'react';

export const useBonusWidgetLocation = () => {
	const [bonusWidgetLocation, setBonusWidgetLocation] = useState(
		EYandexMetrikaVariants.control
	);

	const bonusWidgetYmabLocation = valueOfYandexMetrikaTest(
		AnalyticsTestsNameE?.BONUS_WIDGET
	);

	useEffect(() => {
		if (bonusWidgetYmabLocation) {
			setBonusWidgetLocation(bonusWidgetYmabLocation);
		}
	}, [bonusWidgetYmabLocation]);

	return { bonusWidgetLocation };
};
