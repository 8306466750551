import React, { useEffect, useState } from 'react';
import {
	AddedBonusBalanceToast,
	AddedFreespinsToast,
	DefaultToast,
	LevelUpToast,
	SuccessToast
} from '@components/common/global-settings';
import { useLocales } from '@lib/hooks';
import { deleteBonusBalances, setFreeSpinsInfo } from '@store/reducers/bonus/dispatchers';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import {
	setIsUpdateGameProviders,
	setIsUpdateGamesList
} from '@store/reducers/common-ui/dispatchers';
import {
	setBalance,
	setUserLevelSum,
	setBonusBalance,
	setBonusBalanceWager,
	setUserLevelId,
	setDepositWager
} from '@store/reducers/user/dispatchers';
import { userSelectors } from '@store/reducers/user/selectors';
import { formatNumber, renderCurrencySymbol } from '@utils';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';
import { apiConfig } from '@config/api';
import convertToUsers from '@utils/convertToUsers';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import toast from 'react-hot-toast';
import { paymentSelectors } from '@store/reducers/payment/selectors';
import {
	setQrPaymentInfo,
	setUserConfirmedPayment
} from '@store/reducers/payment/dispathcers';
import { getPaymentProviders } from '@lib/api/payment';
import { getBonusBalances } from '@lib/api/bonus';

enum CurrentUserEvents {
	balanceUpdated = 'balance:update',
	bonusBalanceTransfer = 'bonusBalance:transfer',
	bonusBalanceUpdate = 'bonusBalance:update',
	bonusBalanceAdded = 'bonus-balance:create-entry',
	bonusBalanceActivate = 'bonus-balance:activate',
	bonusBalanceExpired = 'bonus-balance:remove',
	gameWon = 'game:win',
	bonusToAccountTransferred = 'rmw-balance:transfer',
	invoiceSuccess = 'transaction:invoice:success',
	withdrawSuccess = 'transaction:withdraw:success',
	bonusBalanceUpdated = 'rmw-balance:update',
	newReferralBonus = 'referral:bonus',
	userLevelUpdate = 'user-level:update',
	freeSpinsAdded = 'freespin:added',
	freeSpinsUpdate = 'freespin:updated',
	paymentMethodsUpdates = 'payment-service:update',
	userLevelSumUpdate = 'user-level-sum:update',
	gameProvidersUpdates = 'game-provider:update',
	gameListUpdates = 'game:update',
	depositWagerUpdate = 'depositWager:update'
}

export const SocketComponent = () => {
	const { isAuthed, authToken } = useSelector(authSelectors.authInfo);

	const socketUri = process.env.REACT_APP_EVENTS_URL + apiConfig.events.user.users;

	const { currency, bonusBalanceEnableValue, bonusBalance, userLevelId, role } =
		useSelector(userSelectors.userInfo);
	const { qrPaymentInfo } = useSelector(paymentSelectors.paymentInfo);
	const { freeSpinsInfo } = useSelector(bonusSelectors.bonusInfo);

	const [isAlreadyGetPush, setIsAlreadyGetPush] = useState(false);

	const { localizeText } = useLocales({
		path: 'global',
		node: 'eventNotifications'
	});

	const { sendMessage, readyState, lastJsonMessage } = useWebSocket(socketUri, {
		reconnectInterval: (att: number) => att * 5,
		reconnectAttempts: 10,
		share: true,
		heartbeat: { interval: 15000, message: JSON.stringify({ event: 'ping' }) },
		retryOnError: true
	});

	useEffect(() => {
		if (readyState === ReadyState?.OPEN && authToken) {
			sendMessage(JSON.stringify({ event: 'auth', data: { token: authToken } }));
		}
	}, [readyState, isAuthed]);

	const checkMessage = (type: CurrentUserEvents, data: any) => {
		const upadteLevelHandler = () => {
			setUserLevelId(data);
			toast((t) => (
				<LevelUpToast
					t={t}
					level={data || userLevelId}
				/>
			));
		};

		const freeSpinHandler = () => {
			let newArr = freeSpinsInfo?.map((e) => {
				if (e?.gameId === data.gameId) {
					return { ...e, amount: data?.amount };
				}

				return e;
			});

			if (data?.amount === 0) {
				newArr = newArr?.filter((e) => e?.gameId !== data?.gameId);
			}

			setFreeSpinsInfo(newArr || [{ ...data, id: data?.freespinId }]);
		};

		const freespinAddHandler = () => {
			setFreeSpinsInfo([
				...(freeSpinsInfo || []),
				{ ...data, id: data?.freespinId, winAmount: 0 }
			]);

			toast((t) => (
				<AddedFreespinsToast
					t={t}
					amount={data?.amount}
				/>
			));
		};

		const paymentMethodsUpdatedHandler = () => {
			if (isAuthed) {
				getPaymentProviders(currency, role);
			}
		};

		const gameProvidersUpdatedHandler = () => {
			setIsUpdateGameProviders(true);
			setTimeout(() => setIsUpdateGameProviders(false), 1000);
		};

		const gamesListUpdatedHandler = () => {
			setIsUpdateGamesList(true);
			setTimeout(() => setIsUpdateGamesList(false), 1000);
		};

		const bonusBalanceActivateHandler = async () => {
			await getBonusBalances();

			setBonusBalance(data?.bonusBalance);
			setBonusBalanceWager(data?.bonusBalanceWager);
		};

		const balanceUpdateHandler = () => {
			setBalance(data);

			if (
				!isAlreadyGetPush &&
				data / 100 <= bonusBalanceEnableValue &&
				Boolean(bonusBalance)
			) {
				toast((t) => (
					<DefaultToast
						t={t}
						title={localizeText('enable_bonus_balance_title')}
						text={localizeText('enable_bonus_balance', {
							sum: formatNumber(convertToUsers(data)),
							cur: renderCurrencySymbol(currency?.toLowerCase())
						})}
						translated={true}
					/>
				));

				setIsAlreadyGetPush(true);
			}
		};

		const invoiceSuccess = () => {
			if (data?.id === qrPaymentInfo) {
				setUserConfirmedPayment(true);
				setQrPaymentInfo({ ...qrPaymentInfo, step: 2 });
			}

			toast((t) => (
				<SuccessToast
					t={t}
					text={localizeText('transaction_invoice_success_before', {
						sum: formatNumber(convertToUsers(data)),
						cur: renderCurrencySymbol(currency?.toLowerCase())
					})}
					translated={true}
				/>
			));
		};

		switch (type) {
			case CurrentUserEvents.balanceUpdated:
				return balanceUpdateHandler();
			case CurrentUserEvents.depositWagerUpdate:
				return setDepositWager(data);
			case CurrentUserEvents.userLevelSumUpdate:
				return setUserLevelSum(data);
			case CurrentUserEvents.bonusBalanceUpdate:
				return bonusBalanceActivateHandler();
			case CurrentUserEvents.bonusBalanceExpired:
				return deleteBonusBalances(data?.bonusBalanceId);
			case CurrentUserEvents.bonusBalanceAdded:
				return toast((t) => (
					<AddedBonusBalanceToast
						t={t}
						bonusAmount={data?.bonusAmount}
					/>
				));
			case CurrentUserEvents.bonusBalanceActivate:
				return bonusBalanceActivateHandler();
			case CurrentUserEvents.paymentMethodsUpdates:
				return paymentMethodsUpdatedHandler();
			case CurrentUserEvents.gameProvidersUpdates:
				return gameProvidersUpdatedHandler();
			case CurrentUserEvents.gameListUpdates:
				return gamesListUpdatedHandler();
			case CurrentUserEvents.freeSpinsAdded:
				return freespinAddHandler();
			case CurrentUserEvents.freeSpinsUpdate:
				return freeSpinHandler();
			case CurrentUserEvents.bonusBalanceTransfer:
				return toast((t) => (
					<DefaultToast
						t={t}
						title={localizeText('bonus_balance_transfer_title')}
						text={localizeText('bonus_balance_transfer', {
							sum: formatNumber(convertToUsers(data)),
							cur: renderCurrencySymbol(currency?.toLowerCase())
						})}
						translated={true}
					/>
				));
			case CurrentUserEvents.userLevelUpdate:
				return upadteLevelHandler();
			case CurrentUserEvents.bonusToAccountTransferred:
				return toast((t) => (
					<SuccessToast
						t={t}
						text={localizeText('rmw_balance_transfer_before', {
							sum: formatNumber(convertToUsers(data)),
							cur: renderCurrencySymbol(currency?.toLowerCase())
						})}
						translated={true}
					/>
				));
			case CurrentUserEvents.invoiceSuccess:
				return invoiceSuccess();
			case CurrentUserEvents.withdrawSuccess:
				return toast((t) => (
					<SuccessToast
						t={t}
						text={'transaction_withdraw_success'}
						translated={true}
					/>
				));
			case CurrentUserEvents.bonusBalanceUpdated:
				return toast((t) => (
					<SuccessToast
						t={t}
						text={localizeText('rmw_balance_update_before', {
							sum: formatNumber(convertToUsers(data)),
							cur: renderCurrencySymbol(currency?.toLowerCase())
						})}
						translated={true}
					/>
				));
			case CurrentUserEvents.newReferralBonus:
				return toast((t) => (
					<SuccessToast
						t={t}
						text={localizeText('referral_bonus_before', {
							username: data?.username
						})}
						translated={true}
					/>
				));
			default:
				return null;
		}
	};

	useEffect(() => {
		const message: { type: CurrentUserEvents; payload: any } = lastJsonMessage as any;

		if (message && message?.type) {
			const { type, payload } = message;

			checkMessage(type, payload);
		}
	}, [lastJsonMessage]);

	return null;
};
