import React, { useState } from 'react';
import { TelegramLinkBot } from '@config/global';
import {
	AngleBottom,
	AngleTop,
	CancelSvg,
	CommentNewOnlineSvg,
	TelegramNewSvg
} from '@components/svg';
import { CommentNewSvg } from '@components/svg';
import { openCarrotChat, sendAnalyticsHandler } from '@utils';
import s from './style.module.scss';
import cn from 'classnames';
import { AnalyticsEventType, EYandexMetrikaVariants } from '@types';
import { useBonusWidgetLocation } from '@lib/hooks/useBonuseWidgetLocation';

export const SupportWidget = () => {
	const [isOpenedSupport, setIsOpenedSupport] = useState(false);

	const { bonusWidgetLocation } = useBonusWidgetLocation();

	const onlineSupportClickHanlder = () => {
		setIsOpenedSupport(!isOpenedSupport);
		openCarrotChat();

		sendAnalyticsHandler(AnalyticsEventType?.click_support_choose_online_support);
	};

	const supportChooseTgClickHandler = () => {
		setIsOpenedSupport(!isOpenedSupport);
		window.open(TelegramLinkBot, '_blank');

		sendAnalyticsHandler(AnalyticsEventType?.click_support_choose_tg);
	};

	const supportClickHandler = () => setIsOpenedSupport(!isOpenedSupport);

	return (
		<>
			<div
				className={cn(s.supportWrapper, { [s.hovered]: isOpenedSupport })}
				onClick={supportClickHandler}
			>
				<div
					className={cn(s.imageWrapper, {
						[s.imageWrapperSide]: bonusWidgetLocation === EYandexMetrikaVariants.side
					})}
				>
					{bonusWidgetLocation === EYandexMetrikaVariants.side && (
						<AngleTop className={s.angleTop} />
					)}
					{isOpenedSupport ? <CancelSvg /> : <CommentNewSvg />}
					{bonusWidgetLocation === EYandexMetrikaVariants.side && (
						<AngleBottom className={s.angleBottom} />
					)}
				</div>
			</div>
			{isOpenedSupport && (
				<div className={s.modalWrapper}>
					<button onClick={onlineSupportClickHanlder}>
						<CommentNewOnlineSvg />
						<span>Online Support</span>
					</button>
					<div className={s.dividerLine} />
					<button onClick={supportChooseTgClickHandler}>
						<TelegramNewSvg />
						<span>Telegram</span>
					</button>
				</div>
			)}
		</>
	);
};
