import React, { useEffect } from 'react';
import i18n from 'i18next';
import OneSignal from 'react-onesignal';
import { unstable_HistoryRouter as Router, HistoryRouterProps } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { MobileAppBanner } from '@components/common/page';
import { MainContent, ContentWrapper } from '@components/app/parts';
import { LoginModal, ModalsWrapper, RegisterModal } from '@components/common/modals';
import { store } from '@store/index';
import { isTapX } from '@config/global';
import { Toaster } from 'react-hot-toast';
import { history } from '@lib/hooks';
import s from '../../scss/_variables.module.scss';
import { ModalProvider } from '@components/shared/modal';

const body = document?.querySelector('body');

if (body && isTapX) {
	body.classList.add(s.tapx);
}

export const App = () => {
	useEffect(() => {
		if (process.env.REACT_ONE_SIGNAL_APP_ID) {
			OneSignal.init({
				appId: process.env.REACT_ONE_SIGNAL_APP_ID || '',
				serviceWorkerPath: './OneSignalSDKWorker.js'
			});
		}
	}, []);

	return (
		<I18nextProvider i18n={i18n}>
			<Provider store={store}>

				<Router history={history as unknown as HistoryRouterProps['history']}>
					<ModalProvider>
						<MainContent>
							<MobileAppBanner />

							<ContentWrapper />

							<ModalsWrapper />

							<RegisterModal />

							<LoginModal />
						</MainContent>
					</ModalProvider>
				</Router>

				<Toaster
					containerStyle={{ zIndex: 10001 }}
					toastOptions={{ className: s.toastClass }}
					gutter={26}
					position='bottom-right'
				/>
			</Provider>
		</I18nextProvider>
	);
};
