import React, { lazy, useEffect, useState } from 'react';
import { pathsConfig } from '@config/paths';
import { RoutePreRenderingWrap } from '@components/routes-wrapper';
import { useCallbackPrompt, useLocales, useUrlLang } from '@lib/hooks';
import { getPaymentProviders } from '@lib/api/payment';
import {
	initPayment,
	setInvoiceValue,
	setSelectedPaymentMethodId
} from '@store/reducers/payment/dispathcers';
import {
	AnalyticsEventType,
	InvoicePaymentResponseDataType,
	PaymentFlows,
	PaymentType,
	SessionStorageParams
} from '@types';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { Helmet } from 'react-helmet';
import { userSelectors } from '@store/reducers/user/selectors';
import { BackArrow, CloseSvg } from '@components/svg';
import { GoHome, Ripple } from '@components/common/elements';
import { useLocation, useNavigate } from 'react-router-dom';
import { paymentSelectors } from '@store/reducers/payment/selectors';
import { useSelector } from 'react-redux';
import { Resolver } from './parts';
import { ClosePaymentToast } from '@components/common/global-settings';
import s from './style.module.scss';
import cn from 'classnames';
import toast from 'react-hot-toast';
import { sendAnalyticsHandler } from '@utils';
import { commonUiConfig } from '@config/common-ui';

const PaymentBody = lazy(() => import('./Payment'));

export const Payment = () => {
	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const components = useLocales({
		path: 'components/routes',
		node: 'Payment'
	});

	const {
		paymentType,
		isPaymentProcessing,
		selectedPaymentMethodId,
		isRequestToWithdrawalSuccess,
		userConfirmedPayment,
		paymentMethodsInvoice,
		paymentMethodsWithdraw
	} = useSelector(paymentSelectors.paymentInfo);

	const { isMobile, isAndroidBanner } = useSelector(commonUISelectors.commonUIInfo);

	const { currency, successInvoiceCount, balance, role } = useSelector(
		userSelectors.userInfo
	);

	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
		paymentType === PaymentType.invoice && !userConfirmedPayment
	);

	const [toastId, settToastId] = useState<string | undefined>();
	const [cancelModal, setCancelModal] = useState<boolean>(false);
	const [cancelModalId, setCancelModalId] = useState<null | number>(null);

	const [innerPlatformFlow, setInnerPlatformFlow] = useState<null | PaymentFlows>(null);
	const [dataForPay, setDataForPay] = useState<null | InvoicePaymentResponseDataType>(
		null
	);

	const getPaymentProvidersHandler = () => getPaymentProviders(currency, role);

	const initMethods: Array<Function> = [getPaymentProvidersHandler];

	useEffect(() => {
		if (showPrompt) {
			toast(
				(t) => {
					settToastId(t?.id);

					return (
						<ClosePaymentToast
							t={t}
							balance={balance}
							successInvoiceCount={successInvoiceCount}
							confirmNavigation={confirmNavigation}
							cancelNavigation={cancelNavigation}
						/>
					);
				},
				{ duration: commonUiConfig.infinityDuration }
			);
		} else {
			if (toastId) {
				toast?.dismiss(toastId);
			}
		}
	}, [showPrompt]);

	useEffect(() => {
		cancelNavigation && cancelNavigation();
	}, [selectedPaymentMethodId, paymentType]);

	const location = useLocation();

	useEffect(() => {
		if (paymentMethodsInvoice?.length || paymentMethodsWithdraw?.length) {
			if (location?.state?.withdrawal) {
				initPayment(PaymentType.withdrawal);

				window.history.replaceState({}, document.title);
				location.state.withdrawal = null;
			} else {
				initPayment(PaymentType.invoice);
				setSelectedPaymentMethodId(null);
			}
		}
	}, [location, paymentMethodsInvoice, paymentMethodsWithdraw]);

	const backBtnHandler = () => {
		if (innerPlatformFlow) {
			setInnerPlatformFlow(null);
			setDataForPay(null);
		} else {
			setSelectedPaymentMethodId(null);
			setInvoiceValue('');
		}
	};

	const crossBtnHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.close_payment, {
			close_payment_tab: paymentType
		});

		if (!selectedPaymentMethodId && isMobile) {
			setSelectedPaymentMethodId(null);
			setInvoiceValue('');
			setInnerPlatformFlow(null);

			const returnGame = sessionStorage.getItem(SessionStorageParams?.returnGame);

			if (returnGame) {
				navigate(langUrlPrefix + returnGame);
				sessionStorage.removeItem(SessionStorageParams?.returnGame);
			} else {
				navigate(langUrlPrefix + pathsConfig?.home?.tabs.casino.link);
			}
		} else {
			navigate(langUrlPrefix + pathsConfig?.home?.tabs.casino.link);
		}
	};

	const cancelModalHandler = (id?: number) => {
		setCancelModal(!cancelModal);
		id && setCancelModalId(id);
	};

	const renderResolver = () => {
		return (
			<Resolver
				innerPlatformFlow={innerPlatformFlow}
				setInnerPlatformFlow={setInnerPlatformFlow}
				paymentType={paymentType}
				localizeText={components?.localizeText}
				isPaymentProcessing={isPaymentProcessing}
				isRequestToWithdrawalSuccess={isRequestToWithdrawalSuccess}
				crossBtnHandler={crossBtnHandler}
				setDataForPay={setDataForPay}
				dataForPay={dataForPay}
				cancelModalHandler={cancelModalHandler}
			/>
		);
	};

	//TODO: Нужен рефак.
	return (
		<>
			<Helmet>
				<link
					rel='canonical'
					href={window?.location?.href}
				/>
			</Helmet>
			<RoutePreRenderingWrap initMethods={initMethods}>
				<React.Suspense>
					<div className={s.blockPayment}>
						<div className={s.wrapper}>
							{isMobile && (
								<div className={cn(s.bg, { [s.opened]: selectedPaymentMethodId })} />
							)}
							{isMobile ? (
								<div className={s.header}>
									{components.localizeText('cashier')}
									<button onClick={crossBtnHandler}>
										<CloseSvg />
										<Ripple />
									</button>
								</div>
							) : (
								<GoHome
									analiticsHandler={() =>
										sendAnalyticsHandler(AnalyticsEventType?.close_payment, {
											close_payment_tab: paymentType
										})
									}
								/>
							)}

							<PaymentBody
								cancelModalHandler={cancelModalHandler}
								cancelModalId={cancelModalId}
								cancelModal={cancelModal}
								renderResolver={renderResolver}
							/>
						</div>

						{isMobile && (
							<div
								className={cn(s.wrapperPayType, {
									[s.opened]: selectedPaymentMethodId,
									[s.androidBanner]: isAndroidBanner
								})}
							>
								<div className={s.header}>
									{selectedPaymentMethodId && (
										<button
											onClick={backBtnHandler}
											className={s.BackArrow}
										>
											<BackArrow />
										</button>
									)}
									{paymentType === PaymentType?.invoice
										? components.localizeText('enter_amount')
										: components.localizeText('withdrawal')}
									<button onClick={crossBtnHandler}>
										<CloseSvg />
										<Ripple />
									</button>
								</div>
								<div className={s.window}>{renderResolver()}</div>
							</div>
						)}
					</div>
				</React.Suspense>
			</RoutePreRenderingWrap>
		</>
	);
};
