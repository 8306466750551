import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import dayjs from 'dayjs';

import { formatNumber, renderCurrencySymbol } from '@utils';
import convertToUsers from '@utils/convertToUsers';
import { useLocales } from '@lib/hooks';

import { InfoWarn } from '@components/svg';

import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';

import { ModalType } from '@types';
import { EBonusBalanceStatusVariants, EBonusBalanceVariants } from '../../type';

import s from './style.module.scss';

export const HistoryBonusBalancesBlock = () => {
	const { bonusBalancesHistory } = useSelector(bonusSelectors.bonusInfo);
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'AllBonus'
	});

	const setModalTypeClickHandler = (type: string) => {
		setModalType(modalSwitcher(type));
	};

	const renderStatus = (status: string, expiresAt: string | null) => {
		switch (status) {
			case EBonusBalanceStatusVariants.EXPIRED:
				return localizeText('expired') + dayjs(expiresAt).format('DD.MM.YYYY');
			case EBonusBalanceStatusVariants.CANCELLED:
				return localizeText('cancelled') + dayjs(expiresAt).format('DD.MM.YYYY');
			case EBonusBalanceStatusVariants.FINISHED:
			case EBonusBalanceStatusVariants.TRANSFERRED:
				return localizeText('finished') + dayjs(expiresAt).format('DD.MM.YYYY');
			default:
				return '';
		}
	};

	const modalSwitcher = (type: string) => {
		switch (type) {
			case EBonusBalanceVariants.DEPOSIT:
				return ModalType.bonusDepositModal;
			case EBonusBalanceVariants.CASHBACK:
				return ModalType.cashbackModal;
			case EBonusBalanceVariants.PROMO:
				return ModalType.promoBonusModal;
			case EBonusBalanceVariants.FREESPIN_WIN:
				return ModalType.freespinsModal;
			case EBonusBalanceVariants.LEVEL_UP:
				return ModalType.levelUpModal;
			case EBonusBalanceVariants.APP_INSTALL:
				return ModalType.appInstallationBonusModal;
			case EBonusBalanceVariants.TASK_BONUS:
				return ModalType.tgBonusModal;
			default:
				return ModalType.bonusDepositModal;
		}
	};

	return (
		<div className={s.content}>
			<div className={s.bonusBlock}>
				<div className={s.bonusList}>
					<div className={s.bonusBlock_bottom}>
						<>
							{bonusBalancesHistory?.data.map((balance) => {
								const {
									status,
									id,
									currency,
									bonusBalance,
									type,
									expiresAt,
									transactionAmount
								} = balance;

								const isTransferred = status === EBonusBalanceStatusVariants.TRANSFERRED;

								return (
									<>
										<div
											key={id}
											className={cn(s.invoiceItem)}
										>
											<div className={cn(s.invoiceItem_top)}>
												<div
													className={s.depNum}
													onClick={() => setModalTypeClickHandler(type)}
												>
													<InfoWarn />
												</div>
												<div className={s.invoiceItem_top_content}>
													<div className={s.progressBlocK}>
														<div className={s.descrBlock}>
															<span className={s.disabled}>
																{localizeText(`${type}_bonus`)}
															</span>
															<span>
																{renderCurrencySymbol(currency?.toLowerCase())}{' '}
																{formatNumber(
																	convertToUsers(
																		(isTransferred ? transactionAmount : bonusBalance) ||
																			0
																	)
																)}
															</span>
														</div>
													</div>
													<div className={s.divider} />
													<div
														className={cn(s.dateBonusesWrapper, {
															[s.green]: isTransferred && transactionAmount
														})}
													>
														{renderStatus(status, expiresAt)}
													</div>
												</div>
											</div>
										</div>
									</>
								);
							})}
						</>
					</div>
				</div>
			</div>
		</div>
	);
};
