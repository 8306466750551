import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { pathsConfig } from '@config/paths';
import { metaConfig } from '@config/meta';
import { casinoType } from '@utils/casinoType';
import { getImageByURL, sendAnalyticsHandler } from '@utils';
import { getBonusBalances, getFreeSpinsQty } from '@lib/api/bonus';
import { useLocales, useUrlLang } from '@lib/hooks';

import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { authSelectors } from '@store/reducers/auth/selectors';

import { BonusBalancesBlock } from './parts';
import AllBonusBody from './AllBonus';
import { Footer } from '@components/common/page';
import { CloseSvg } from '@components/svg';

import s from './style.module.scss';

import { AnalyticsEventType } from '@types';
import { getBonusHistory } from '@lib/api/bonus/getBonusHistory';
import { HistoryBonusBalancesBlock } from './parts/history-bonus-balance-block';

const tabs = [
	{ key: 'myBonuses', name: 'myBonuses' },
	{ key: 'allBonuses', name: 'allBonuses' },
	{ key: 'historyBonuses', name: 'historyBonuses' }
];

export const AllBonus = () => {
	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const [selectedTab, setSelectedTab] = useState(tabs[1]?.key);
	const { bonusBalances, bonusBalancesHistory } = useSelector(bonusSelectors.bonusInfo);

	const activeRef = useRef<HTMLDivElement>(null);

	const { localizeText } = useLocales({
		path: 'global',
		node: 'bonusesPage'
	});

	const closeModal = () => {
		navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link);

		sendAnalyticsHandler(AnalyticsEventType?.close_back, {
			close_back: 'all_bonuses'
		});
	};

	const handleSetTabClickHandler = () => {
		setSelectedTab('allBonuses');
	};

	const handleUpdateTabClickHandler = (key: string) => {
		setSelectedTab(key);

		sendAnalyticsHandler(AnalyticsEventType?.transaction_click_tab, {
			transaction_tab_name: key
		});
	};

	const updateData = async () => {
		await getBonusBalances();
		await getFreeSpinsQty();
		await getBonusHistory();
	};

	useEffect(() => {
		isAuthed && updateData();
	}, [isAuthed]);

	const renderHeaderTabs = () => (
		<div className={s.tabList}>
			{tabs?.map((tab) => {
				const { key, name } = tab;

				const isActive = selectedTab === key;

				return (
					<div
						key={key}
						ref={isActive ? activeRef : null}
						className={cn(s.tabItem, { [s.active]: isActive })}
						onClick={() => handleUpdateTabClickHandler(key)}
					>
						<h4 className={s.tabItemName}>{localizeText(`${name}`)}</h4>
					</div>
				);
			})}
		</div>
	);

	return (
		<>
			<Helmet>
				<title>{metaConfig.metatags[casinoType()].bonuses?.title}</title>
				<meta
					name='description'
					content={metaConfig.metatags[casinoType()].bonuses?.description}
				/>
				<link
					rel='canonical'
					href={window?.location?.href}
				/>
			</Helmet>
			<div className={s.wrapper}>
				{isMobile && (
					<div
						className={cn(s.headerWrapper, { [s.nonAuthHeader]: !isAuthed && isMobile })}
					>
						<div>
							<div className={s.title}>{localizeText('title')}</div>
							<div
								onClick={closeModal}
								className={s.headerClose}
							>
								<CloseSvg />
							</div>
						</div>
						{isAuthed && renderHeaderTabs()}
					</div>
				)}

				<div className={s.typeBlock}>
					<div className={cn({ [s.wrapperTabList]: !isMobile && isAuthed })}>
						{!isMobile && isAuthed && renderHeaderTabs()}
					</div>

					{selectedTab === 'allBonuses' && <AllBonusBody />}
					{selectedTab === 'myBonuses' && (
						<>
							{isAuthed && !!bonusBalances?.length && (
								<>
									<BonusBalancesBlock />
									<div className={s.divider} />
								</>
							)}
							{isAuthed && !bonusBalances?.length && (
								<div className={s.container}>
									<img
										className={s.bonusImg}
										src={getImageByURL(
											'../../../../../../assets/img/bonus/bonusImage.webp'
										)}
									/>

									<div className={s.textTitle}>{localizeText('noActiveBonuses')}</div>
									<span className={s.textDesc}>{localizeText('receiveBonuses')}</span>
									<button
										className={s.buttonAllBonuses}
										onClick={handleSetTabClickHandler}
									>
										{localizeText('allBonuses')}
									</button>
								</div>
							)}
						</>
					)}
					{selectedTab === 'historyBonuses' && (
						<>
							{isAuthed && !!bonusBalancesHistory?.data?.length && (
								<>
									<HistoryBonusBalancesBlock />
									<div className={s.divider} />
								</>
							)}
							{isAuthed && !bonusBalancesHistory?.data?.length && (
								<div className={s.container}>
									<img
										className={s.bonusImg}
										src={getImageByURL(
											'../../../../../../assets/img/bonus/bonusImage.webp'
										)}
									/>

									<div className={s.textTitle}>{localizeText('noBonusHistory')}</div>
									<span className={s.textDesc}>{localizeText('receiveBonuses')}</span>
									<button
										className={s.buttonAllBonuses}
										onClick={handleSetTabClickHandler}
									>
										{localizeText('allBonuses')}
									</button>
								</div>
							)}
						</>
					)}
				</div>
			</div>

			<Footer />
		</>
	);
};
