import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { EditPencil, InfoWarn } from '@components/svg';
import { Button, FormError, PhoneInput } from '@components/common/elements';
import { useFormik } from 'formik';
import { updateCurrentUserData } from '@lib/api/user';
import { AnalyticsEventType, Settings } from '@types';
import * as Yup from 'yup';
import s from './style.module.scss';
import { sendAnalyticsHandler } from '@utils';
import { getCountries } from 'react-phone-number-input';
import { getPhoneNumberByRegion } from '@utils/phoneNumberUtil';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { CountryCode } from 'libphonenumber-js';

type PropsType = {
	// codeOpenModalHandler: (type: 'phone' | 'email') => void;
	isEdit: boolean;
	setIsEdit: (verified: boolean) => void;
};

const PhoneBlock = ({ isEdit, setIsEdit }: PropsType) => {
	const { phone, locationData } = useSelector(userSelectors.userInfo);
	const [countryCode, setCountryCode] = useState(
		locationData?.countryCode || getCountries()[0]
	);

	const phoneUtil = PhoneNumberUtil.getInstance();

	const { nationalPrefix } = getPhoneNumberByRegion(countryCode);
	const [isPending, setIsPending] = useState(false);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Profile'
	});

	const handleSubmit = async (values: { phone: string }) => {
		setIsPending(true);
		try {
			const currentUserPhone = `+${nationalPrefix}${values?.phone}`?.replace(
				/[\s-]/g,
				''
			);

			await updateCurrentUserData(Settings.phone, currentUserPhone);
		} catch (error) {
			console.error(error);
		} finally {
			setIsPending(false);
		}
	};

	const countryCodePhone = phoneUtil.getCountryCodeForRegion(
		locationData?.countryCode || getCountries()[0]
	);
	const phoneWithoutCode = phone?.replace(`+${countryCodePhone}`, '').trim();

	const validationSchema = Yup.object().shape({
		phone: Yup.string()
			.phone(countryCode as CountryCode, localizeText('invalid_phone'))
			.required(localizeText('can_not_be_empty'))
	});

	const formik = useFormik({
		initialValues: {
			phone: phoneWithoutCode || ''
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		if (phoneWithoutCode) {
			formik?.setFieldValue('phone', phoneWithoutCode);
			setIsEdit(true);
		}
	}, [phoneWithoutCode]);

	return (
		<div className={s.block}>
			<div className={s.formBlock}>
				<form
					onSubmit={formik.handleSubmit}
					className={s.form}
				>
					<PhoneInput
						onNumberChange={(num) => formik.setFieldValue('phone', num)}
						setCountryCode={setCountryCode}
						onBlur={(isValid) => {
							formik?.setFieldTouched('phone', !isValid);
						}}
						isDisabled={isEdit}
						initValue={formik?.values?.phone}
						error={!!formik?.touched?.phone && Boolean(formik?.errors?.phone)}
					/>
					{isEdit ? (
						<button
							onClick={() => {
								setIsEdit(false);
								sendAnalyticsHandler(AnalyticsEventType?.profile_edit_phone);
							}}
							type='button'
							className={s.editBtn}
						>
							<EditPencil />
						</button>
					) : (
						<Button
							rippleAnimation
							className={s.button}
							btnType='submit'
							text={localizeText('confirm')}
							isDisabled={
								isPending || (!isEdit && formik?.values?.phone === phoneWithoutCode)
							}
						/>
					)}
				</form>
				<FormError
					error={formik?.errors?.phone}
					isTouched={!!formik?.touched?.phone}
				/>
			</div>

			{isEdit ? (
				<div className={s.confirmed}>
					<InfoWarn />
					{localizeText('phone_confirmed')}
				</div>
			) : (
				<div className={s.needConfirm}>
					<InfoWarn />
					{localizeText('need_confirm_phone')}
				</div>
			)}
		</div>
	);
};

export default PhoneBlock;
