import { LocalizeText } from '@types';
import { millisecondsUntilNextFriday } from './millisecondsUntilNextFriday';

export function formatTimeUntilNextFriday(localizeText: LocalizeText): string {
	const MS_UNTIL_NEXT_FRIDAY = millisecondsUntilNextFriday();
	const MS_IN_ONE_DAY = 24 * 60 * 60 * 1000;
	const MS_IN_ONE_HOUR = 60 * 60 * 1000;

	if (MS_UNTIL_NEXT_FRIDAY >= MS_IN_ONE_DAY) {
		const time = Math.floor(MS_UNTIL_NEXT_FRIDAY / MS_IN_ONE_DAY);

		return localizeText('cashback_bonus_timer_days', { time });
	}

	const time = Math.floor(MS_UNTIL_NEXT_FRIDAY / MS_IN_ONE_HOUR);

	return localizeText('cashback_bonus_timer_hours', { time });
}
