import React from 'react';
import s from './style.module.scss';
export const ActiveBonusesWidget = () => {
	return (
		<div className={s.inner}>
			<div>1111</div>

			<div>11111</div>
		</div>
	);
};
