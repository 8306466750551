import React, { useEffect, useState } from 'react';
import { useDebounce, useLocales } from '@lib/hooks';
import s from './style.module.scss';
import cn from 'classnames';
import { BackArrow, CloseSvg } from '@components/svg';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { Button, FormError, Input, Preloader } from '@components/common/elements';
import { useFormik } from 'formik';
import { Trans } from 'react-i18next';
import {
	confirmEmail,
	confirmEmailCode,
	confirmPhone,
	confirmPhoneCode
} from '@lib/api/user';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';

type PropsType = {
	isCodeModal: boolean;
	codeModalType: 'phone' | 'email' | '';
	codeModalHandler: () => void;
	counter: number;
	setCounter: React.Dispatch<React.SetStateAction<number>>;
};

const CodeScreen = (props: PropsType) => {
	const { codeModalType, isCodeModal, codeModalHandler, counter, setCounter } = props;
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Profile'
	});

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const [loader, setLoader] = useState(false);

	const closeModalHandler = () => {
		codeModalHandler();
		setModalType(null);
	};

	const handleSubmit = async (code: string) => {
		setLoader(true);

		const successFunc = (success: boolean) => {
			setLoader(false);
			success && codeModalHandler();
		};

		if (codeModalType === 'email') {
			await confirmEmailCode(code, successFunc);
		}

		if (codeModalType === 'phone') {
			await confirmPhoneCode(code, successFunc);
		}
	};

	const formik = useFormik({
		initialValues: {
			code: ''
		},
		onSubmit: () => undefined
	});

	const debouncedValue = useDebounce<string>(formik?.values?.code, 500);

	useEffect(() => {
		if (debouncedValue && formik?.values?.code?.length === 6) {
			handleSubmit(debouncedValue);
		}
	}, [debouncedValue]);

	const sendAgainHandler = async () => {
		setCounter(70);

		if (codeModalType === 'email') {
			await confirmEmail();
		}

		if (codeModalType === 'phone') {
			await confirmPhone();
		}
	};

	return (
		<div className={cn(s.wrapper, { [s.opened]: codeModalType && isCodeModal })}>
			{isMobile && (
				<div className={s.header}>
					<div className={s.headerWrapper}>
						<div
							onClick={codeModalHandler}
							className={s.headerBack}
						>
							<BackArrow />
						</div>
						<div className={s.headerTitle}>{localizeText(`verify_${codeModalType}`)}</div>
						<div
							onClick={closeModalHandler}
							className={s.headerClose}
						>
							<CloseSvg />
						</div>
					</div>
				</div>
			)}

			<div className={s.inner}>
				{!isMobile && (
					<div className={s.header}>
						<div className={s.headerWrapper}>
							<div className={s.headerTitle}>
								{localizeText(`verify_${codeModalType}`)}
							</div>
							<div
								onClick={closeModalHandler}
								className={s.headerClose}
							>
								<CloseSvg />
							</div>
						</div>
					</div>
				)}
				<div className={s.decription}>
					{localizeText(`verify_descr_${codeModalType}`)}
				</div>
				{codeModalType !== 'email' && (
					<form
						onSubmit={formik.handleSubmit}
						className={s.form}
					>
						<Input
							name='code'
							type='text'
							inputMode={'numeric'}
							value={formik?.values?.code}
							label={localizeText(`verify_code_${codeModalType}`)}
							onChange={formik.handleChange}
							error={!!formik?.touched?.code && Boolean(formik?.errors?.code)}
						/>
						<FormError
							error={formik?.errors?.code}
							isTouched={!!formik?.touched?.code}
						/>
					</form>
				)}
				<Button
					handle={sendAgainHandler}
					isDisabled={counter > 0}
					text={
						counter > 0 ? (
							<Trans
								ns='components/routes/translation'
								i18nKey={'Profile.verify_code_again_timer'}
								values={{
									time: `0:${counter < 10 ? '0' + counter : counter}`
								}}
							/>
						) : (
							localizeText('verify_code_again')
						)
					}
					rippleAnimation
					className={s.btn}
				/>
				{loader && <Preloader className={s.loader} />}
			</div>
		</div>
	);
};

export default CodeScreen;
