import { pathsConfig } from '@config/paths';
import { useUrlLang } from '@lib/hooks';
import { linkToRemoteUrl } from '@lib/ux';
import { EUrlQueries } from '@types';
import { getStringQueryFromUrl } from '@utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const PaymentRedirectPage = () => {
	const navigate = useNavigate();

	const { langUrlPrefix } = useUrlLang();

	useEffect(() => {
		const link = getStringQueryFromUrl(EUrlQueries.link);

		link
			? linkToRemoteUrl(link, false)
			: navigate(langUrlPrefix + pathsConfig?.home?.link);
	}, []);

	return null;
};
