import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const TelegramScript = () => {
	const { isTelegram } = useSelector(commonUISelectors.commonUIInfo);

	useEffect(() => {
		const script = document.createElement('script');

		if (isTelegram) {
			script.src = 'https://telegram.org/js/telegram-web-app.js';
			script.async = true;

			document.body.appendChild(script);
		}

		return () => {
			isTelegram && script && document.body.removeChild(script);
		};
	}, [isTelegram]);

	return null;
};
