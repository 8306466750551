import { instance } from '@lib/api';

import {
	AxiosResponsePromise,
	Currencies,
	ICryptoInvoiceDetails,
	IPaymentInitialState,
	InvoicePaymentResponseDataType
} from '@types';
import { detectDeviceType } from '@utils';

type PaymentInvoice = (params: {
	amount: number;
	providerId: number;
	fields?: {
		wallet?: string;
	};
	successUrl?: string;
	failUrl?: string;
	deviceType: string;
	isTelegram?: boolean;
	botTelegramId?: string;
}) => AxiosResponsePromise<InvoicePaymentResponseDataType>;
type PaymentInvoiceCrypto = (params: {
	amount: number;
	network: string;
	currency: string;
	providerId: number;
}) => AxiosResponsePromise<ICryptoInvoiceDetails & { isSuccess: boolean }>;
type PaymentWithdrawal = (params: {
	amount: number;
	cardNumber?: string;
	providerId: number;
	wallet?: string;

	deviceType: string;
	fields?: {
		account_number?: string;
		account_email?: string;
		card_number?: string;
		wallet?: string;
	};
}) => AxiosResponsePromise<string>;
type PaymentWithdrawalCrypto = (params: {
	address: string;
	currency: string;
	network: string;
	amount: number;
	providerId: number;
}) => AxiosResponsePromise<string>;
type WithdrawalCommission = (params: {
	amount: number;
}) => AxiosResponsePromise<
	Omit<IPaymentInitialState['withdrawalCommission'], 'isPending'>
>;
type PaymentProvidersInvoice = (
	currency: Currencies
) => AxiosResponsePromise<IPaymentInitialState['paymentMethodsInvoice']>;
type PaymentProvidersWithdraw = (
	currency: Currencies
) => AxiosResponsePromise<IPaymentInitialState['paymentMethodsWithdraw']>;

type PaymentInvoiceTransctionData = (
	id: string,
	params: {
		invoiceId?: string;
		transactionId?: string;
		trx_id?: string;
		client_details?: string;
	}
) => AxiosResponsePromise<any>;

interface IPaymentService {
	paymentInvoice: PaymentInvoice;
	paymentInvoiceCrypto: PaymentInvoiceCrypto;
	paymentWithdrawal: PaymentWithdrawal;
	paymentWithdrawalCrypto: PaymentWithdrawalCrypto;
	withdrawalCommission: WithdrawalCommission;
	paymentProvidersWithdraw: PaymentProvidersWithdraw;
	paymentProvidersInvoice: PaymentProvidersInvoice;
	paymentInvoiceTransctionData: PaymentInvoiceTransctionData;
	paymentGroupsWithdraw: PaymentProvidersWithdraw;
	paymentGroupsInvoice: PaymentProvidersInvoice;
}

enum PaymentEndpoints {
	paymentInvoice = '/payment/invoice',
	paymentInvoiceCrypto = '/payment/invoice-crypto',
	paymentWithdrawal = '/payment/withdraw',
	paymentWithdrawalCrypto = '/payment/withdraw-crypto',
	withdrawalCommission = '/payment/withdraw/commission',
	paymentProviders = '/payment/providers',
	paymentGroups = '/payment/providers/groups'
}

export const PaymentService: IPaymentService = {
	paymentInvoice: async (params) =>
		await instance.post(PaymentEndpoints.paymentInvoice, params),
	paymentInvoiceCrypto: async (params) =>
		await instance.post(PaymentEndpoints.paymentInvoiceCrypto, params),
	paymentWithdrawal: async (params) =>
		await instance.post(PaymentEndpoints.paymentWithdrawal, params),
	paymentWithdrawalCrypto: async (params) =>
		await instance.post(PaymentEndpoints.paymentWithdrawalCrypto, params),
	withdrawalCommission: async (params) =>
		await instance.post(PaymentEndpoints.withdrawalCommission, params),
	paymentProvidersInvoice: async (currency) =>
		await instance.get(PaymentEndpoints.paymentProviders + `/${currency}/invoice`, {
			params: { device: detectDeviceType() }
		}),
	paymentProvidersWithdraw: async (currency) =>
		await instance.get(PaymentEndpoints.paymentProviders + `/${currency}/withdraw`, {
			params: { device: detectDeviceType() }
		}),

	paymentInvoiceTransctionData: async (id, params) =>
		await instance.post(`/payment/invoice/${id}/confirm`, {
			invoiceId: params?.invoiceId,
			transasactionId: params?.transactionId,
			trx_id: params?.trx_id,
			client_details: params?.client_details
		}),

	paymentGroupsInvoice: async (currency) =>
		await instance.get(PaymentEndpoints.paymentGroups + `/${currency}/invoice`, {
			params: { device: detectDeviceType() }
		}),
	paymentGroupsWithdraw: async (currency) =>
		await instance.get(PaymentEndpoints.paymentGroups + `/${currency}/withdraw`, {
			params: { device: detectDeviceType() }
		})
};
