import React, { lazy, ReactElement } from 'react';
import { ModalType } from '@types';
import { CurrentUserTransactions, Profile } from '@components/routes';
import { Referral } from './parts/referral';
import { PoliticsRouteContentWrapper } from '@components/routes-wrapper/wrappers';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import LevelAwardsModal from './parts/level-awards-modal';
import InformationModal from './parts/information-modal';
import ResetPasswordModal from './parts/reset-pass-modal';
import SetPasswordModal from './parts/set-password-modal';
import WithdrawalBlockedModal from './parts/withdrawal-blocked-modal';
import ReferralLevelAwardsModal from './parts/referral-level-awards-modal';
import CashbackRulesModal from './parts/cashback-rules-modal';
import LogoutModal from './parts/logout-modal';
import RenewPasswordModal from './parts/renew-password-modal';
import PaymentQRModal from './parts/payment-qr-modal';
import LicenseModal from './parts/license-modal';
import WithdrawalInfoModal from './parts/withdrawal-info-modal';
import { BonusMoneyTooltipModal } from './bonus-money-tooltip';
import BonusesModal from './parts/bonuses-modal';
import BonusWidgetModal from './parts/bonus-widget-modal';

const PromocodeModal = lazy(() => import('./parts/promocode-modal'));

type ModalsResolverP = {
	modalType: ModalType | null;
};

export const ModalsResolver = (props: ModalsResolverP) => {
	const { modalType } = props;

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const getLayout = (): ReactElement | null => {
		switch (modalType) {
			case ModalType.setPassword:
				return <SetPasswordModal />;
			case ModalType.bonusWidgetModal:
				return <BonusWidgetModal />;
			case ModalType.bonusMoneyTooltip:
				return <BonusMoneyTooltipModal />;
			case ModalType.paymentQR:
				return <PaymentQRModal />;
			case ModalType.logout:
				return <LogoutModal />;
			case ModalType.license:
				return <LicenseModal />;
			case ModalType.withdrawalBlocked:
				return <WithdrawalBlockedModal />;
			case ModalType.withdrawalInfo:
				return <WithdrawalInfoModal />;
			case ModalType.referralAwards:
				return <ReferralLevelAwardsModal />;
			case ModalType.referral:
				return <Referral />;
			case ModalType.promocode:
				return <PromocodeModal />;
			case ModalType.resetPass:
				return <ResetPasswordModal />;
			case ModalType.transAndBets:
				return isMobile ? <CurrentUserTransactions /> : null;
			case ModalType.profileSettings:
				return isMobile ? <Profile /> : null;
			case ModalType.levelsTable:
				return <LevelAwardsModal />;
			case ModalType.cashbackRulesTable:
				return <CashbackRulesModal />;
			case ModalType.renewPassword:
				return <RenewPasswordModal />;
			case ModalType.politicsTerms:
				return isMobile ? (
					<PoliticsRouteContentWrapper
						localizeNode={'PoliticsTerms'}
						paragraphQty={23}
					/>
				) : null;
			case ModalType.politicsTermsAndConditions:
				return isMobile ? (
					<PoliticsRouteContentWrapper
						localizeNode={'PoliticsTermsAndConditions'}
						paragraphQty={17}
					/>
				) : null;
			case ModalType.politicsSelfPolicy:
				return isMobile ? (
					<PoliticsRouteContentWrapper
						localizeNode={'PoliticsSelfPolicy'}
						paragraphQty={3}
					/>
				) : null;
			case ModalType.politicsPrivacyPolicy:
				return isMobile ? (
					<PoliticsRouteContentWrapper
						localizeNode={'PoliticsPrivacyPolicy'}
						paragraphQty={5}
					/>
				) : null;
			case ModalType.politicsAmlKycPolicy:
				return isMobile ? (
					<PoliticsRouteContentWrapper
						localizeNode={'PoliticsAmlKycPolicy'}
						paragraphQty={5}
					/>
				) : null;
			case ModalType.information:
				return isMobile ? <InformationModal /> : null;
			case ModalType.bonusDepositModal:
			case ModalType.freespinsModal:
			case ModalType.promoBonusModal:
			case ModalType.cashbackModal:
			case ModalType.levelUpModal:
			case ModalType.tgBonusModal:
			case ModalType.referralBonusModal:
			case ModalType.appInstallationBonusModal:
				return <BonusesModal />;
			default:
				return <></>;
		}
	};

	if (!getLayout()) {
		setModalType(null);
	}

	return <React.Suspense>{getLayout()}</React.Suspense>;
};
