import { createContext, FC } from 'react';

export type TModal = {
	id: string;
	Component: FC;
};

type TModalContext = {
	modals: TModal[];
	openModal(id: string, Component: FC): void;
	closeModal(id: string): void;
};

const defaultValue: TModalContext = {
	modals: [],
	/* eslint-disable @typescript-eslint/no-empty-function */
	openModal() {},
	/* eslint-disable @typescript-eslint/no-empty-function */
	closeModal() {}
};

export const ModalContext = createContext(defaultValue);
