import React, { useEffect } from 'react';
import s from './style.module.scss';
import { Button } from '@components/common/elements';
import cn from 'classnames';
import { ButtonType, ModalType } from '@types';
import {
	convertToUsers,
	downloadURI,
	formatNumber,
	getImageByURL,
	renderCurrencySymbol,
	renderGameUrl
} from '@utils';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocales, useUrlLang } from '@lib/hooks';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { pathsConfig } from '@config/paths';
import { ANDROID_APP_LINK } from '@config/global';
import { ProgressBar } from '@components/common/elements/progress-bar';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { formatTimeUntilNextFriday } from '@utils/formatTimeUntilNextFriday';

type TNotActiveBonusesWidgetProps = {
	updateBonusCounter: (count: number) => void;
};

export const NotActiveBonusesWidget = (props: TNotActiveBonusesWidgetProps) => {
	const { updateBonusCounter } = props;

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'BonusWidgetModal'
	});

	const { currency, successInvoiceCount, userLevels, userLevelId, userLevelSum } =
		useSelector(userSelectors.userInfo);

	const { allRMWBonus, currentWeekCashbackAmount, freeSpinsInfo, bonusBalances } =
		useSelector(bonusSelectors.bonusInfo);

	function getFreespinsAmount() {
		return freeSpinsInfo?.reduce((acc, currentValue) => {
			return acc + currentValue?.amount;
		}, 0);
	}

	const freespinsAmount = getFreespinsAmount();

	const isApkDownloaded = bonusBalances.map((e) => e.type === 'app_install')[0];

	const currentLevel = userLevels?.find((e) => e?.id === userLevelId);

	const bonusForNextLevel =
		currentLevel && currentLevel.level >= 3 ? currentLevel?.level + 1 : 3;

	const nextLevel =
		currentLevel && userLevels?.find((e) => e?.level === currentLevel?.level + 1);

	const levelBonuses = allRMWBonus?.find((bonus) => {
		return (
			bonus?.config?.bonus &&
			bonus?.config?.freespin &&
			bonus?.config?.userLevel === bonusForNextLevel
		);
	});

	const currencySymbol = renderCurrencySymbol(currency?.toLowerCase());

	const depositBonusClickHandler = () => {
		setModalType(ModalType.bonusDepositModal);
	};

	const telegramBonusClickHandler = () => {
		setModalType(null);

		navigate(langUrlPrefix + pathsConfig.telegram_bonus.link);
	};

	const apkBonusClickHandler = () => {
		setModalType(ModalType.appInstallationBonusModal);
	};

	const downloadAppClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		ANDROID_APP_LINK && downloadURI(ANDROID_APP_LINK, 'topx.apk');
	};

	const freespinsClickHandler = () => {
		if (freeSpinsInfo && freeSpinsInfo?.length) {
			navigate(langUrlPrefix + renderGameUrl(freeSpinsInfo[0]?.gameKey));
			setModalType(null);
		}
	};

	const cashbackBonusClickHandler = () => {
		setModalType(null);

		navigate(langUrlPrefix + pathsConfig.cashback_bonus.link);
	};

	const levelsBonusClickHandler = () => {
		setModalType(null);

		navigate(langUrlPrefix + pathsConfig.levels.link);
	};

	useEffect(() => {
		let count = 1;

		if (successInvoiceCount < 5) count += 1;

		if (freespinsAmount) count += 1;

		if (currentLevel?.level && currentLevel?.level <= 20) count += 1;

		if (!isApkDownloaded) count += 1;

		updateBonusCounter(count);
	}, [
		successInvoiceCount,
		levelBonuses,
		updateBonusCounter,
		isApkDownloaded,
		freespinsAmount
	]);

	// TODO
	// ТГ на RMW
	// Счетчик блока для ТГ

	return (
		<div className={s.inner}>
			{successInvoiceCount < 5 && (
				<div
					className={s.bonusWidget}
					onClick={depositBonusClickHandler}
				>
					<div className={s.isBonusViewed}></div>
					<div className={s.bonusWidgetCard}>
						<img
							src={getImageByURL('../../assets/img/bonus-widget/bonus.webp')}
							alt={'deposit-bonus-image'}
						/>
						<div className={s.bonusWidgetCardInfo}>
							<div className={s.bonusWidgetCardInfo__title}>
								{localizeText('deposit_bonus_title')}
							</div>
							<div className={s.bonusWidgetCardInfo__description}>
								{localizeText(`deposit_descr_${successInvoiceCount}`)}
							</div>
						</div>
						<Button
							type={ButtonType.primary}
							className={s.button}
							text={localizeText('deposit_bonus_button')}
						/>
					</div>
				</div>
			)}
			<div
				className={s.bonusWidget}
				onClick={telegramBonusClickHandler}
			>
				<div className={s.isBonusViewed}></div>
				<div className={s.bonusWidgetCard}>
					<img
						src={getImageByURL('../../assets/img/bonus-widget/telegram.webp')}
						alt={'telegram-bonus-image'}
					/>
					<div className={s.bonusWidgetCardInfo}>
						<div className={s.bonusWidgetCardInfo__title}>
							{localizeText('telegram_bonus_title', {
								currency: currencySymbol
							})}
						</div>
						<div className={s.bonusWidgetCardInfo__description}>
							{localizeText('telegram_bonus_description')}
						</div>
					</div>
					<Button
						type={ButtonType.primary}
						className={s.button}
						text={localizeText('telegram_bonus_button')}
					/>
				</div>
			</div>
			{!!freespinsAmount && (
				<div
					className={s.bonusWidget}
					onClick={freespinsClickHandler}
				>
					<div className={s.isBonusViewed}></div>
					<div className={s.bonusWidgetCard}>
						<img
							src={getImageByURL('../../assets/img/bonus-widget/freespins.webp')}
							alt={'freespins-bonus-image'}
						/>
						<div className={s.bonusWidgetCardInfo}>
							<div className={s.bonusWidgetCardInfo__title}>
								{localizeText('freespins_bonus_title', {
									freespins: freespinsAmount
								})}
							</div>
							<div className={s.bonusWidgetCardInfo__description}>
								{'Hot Tripple Sevens'}
							</div>
						</div>
						<Button
							type={ButtonType.primary}
							className={s.button}
							text={localizeText('freespins_bonus_button')}
						/>
					</div>
				</div>
			)}
			{!isApkDownloaded && (
				<div
					className={s.bonusWidget}
					onClick={apkBonusClickHandler}
				>
					<div className={s.isBonusViewed}></div>
					<div className={s.bonusWidgetCard}>
						<img
							src={getImageByURL('../../assets/img/bonus-widget/apk.webp')}
							alt={'apk-bonus-image'}
						/>
						<div className={s.bonusWidgetCardInfo}>
							<div className={s.bonusWidgetCardInfo__title}>
								{localizeText('apk_bonus_title', { currency: currencySymbol })}
							</div>
							<div className={s.bonusWidgetCardInfo__description}>
								{localizeText('apk_bonus_description')}
							</div>
						</div>
						<Button
							type={ButtonType.primary}
							className={s.button}
							text={localizeText('apk_bonus_button')}
							handle={downloadAppClickHandler}
						/>
					</div>
				</div>
			)}
			<div
				className={s.bonusWidget}
				onClick={cashbackBonusClickHandler}
			>
				<div className={s.bonusWidgetCard}>
					<img
						src={getImageByURL('../../assets/img/bonus-widget/cashback.webp')}
						alt={'cashback-bonus-image'}
					/>
					<div className={s.bonusWidgetCardInfo}>
						<div className={s.bonusWidgetCardInfo__title}>
							{localizeText('cashback_bonus_title')}
							<div className={s.bonusWidgetCardInfo__title__image}>
								<span>{`${currentLevel?.cashbackPercentage}%`}</span>
							</div>
						</div>
						<div className={s.bonusWidgetCardInfo__description}>
							{formatTimeUntilNextFriday(localizeText)}
						</div>
					</div>
					<Button
						type={ButtonType.secondary}
						className={s.button}
						text={`${renderCurrencySymbol(currency)} ${formatNumber(convertToUsers(currentWeekCashbackAmount))}`}
					/>
				</div>
			</div>
			{currentLevel?.level && currentLevel?.level <= 20 && (
				<div
					className={s.bonusWidget}
					onClick={levelsBonusClickHandler}
				>
					<div className={s.isBonusViewed}></div>
					<div className={cn(s.bonusWidgetCard, s.bonusWidgetCardLevel)}>
						<img
							src={getImageByURL(
								`../../assets/img/levels/level_${bonusForNextLevel}.webp`
							)}
							alt={'levels-bonus-image'}
						/>
						<div className={cn(s.bonusWidgetCardInfo, s.bonusWidgetCardInfoLevel)}>
							<div className={s.bonusWidgetCardInfo__title}>
								{localizeText('level_bonus_title', {
									level: bonusForNextLevel
								})}
							</div>
							<ProgressBar
								containerClassName={s.bonusWidgetCardProgressBar}
								labelClassName={s.bonusWidgetCardProgressBar__label}
								currentValue={userLevelSum - currentLevel?.wager}
								maxValue={
									nextLevel?.wager
										? nextLevel?.wager - currentLevel?.wager
										: userLevelSum - currentLevel?.wager
								}
								customBg='rgba(231, 236, 245, 0.06)'
							/>
							<div
								className={cn(
									s.bonusWidgetCardInfo__description,
									s.bonusWidgetCardInfoLevel__description
								)}
							>
								<div className={s.bonusWidgetCardInfoLevel__description__amount}>
									<span>{`${renderCurrencySymbol(currency)} ${formatNumber(convertToUsers(nextLevel ? userLevelSum : currentLevel?.wager))}`}</span>
									<span>{`/ ${renderCurrencySymbol(currency)} ${formatNumber(convertToUsers(nextLevel ? nextLevel?.wager : currentLevel?.wager))}`}</span>
								</div>
								{levelBonuses && (
									<div className={s.levelBonuses}>
										{!!levelBonuses?.config?.bonus?.amount && (
											<div className={s.bonusBlock}>
												{renderCurrencySymbol(currency)}{' '}
												{formatNumber(
													convertToUsers(levelBonuses?.config?.bonus?.amount)
												)}
												<img
													className={s.coinImg}
													src={getImageByURL(
														'../../../../../../assets/img/common/coin_without_space.webp'
													)}
												/>
											</div>
										)}

										{levelBonuses?.config?.bonus?.amount &&
											!!levelBonuses?.config?.freespin?.amount && (
												<div className={s.plusBlock}>+</div>
											)}

										{!!levelBonuses?.config?.freespin?.amount && (
											<div className={s.freespinBlock}>
												{levelBonuses?.config?.freespin?.amount} FS
												<img
													className={s.fsImg}
													src={getImageByURL(
														'../../../../../../assets/img/common/free_spins.webp'
													)}
												/>
											</div>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
